.site3 {
  .uvForm-comments {
    margin-bottom: 60px;
  }
  .uvForm-comments .uvFormLegend {
    display: none;
  }
  .uvForumCreateIdea .submit_region,
  .uvForm-comments .submit_region {
    @apply mt-6;
  }
  .uvFormFlash {
    @apply relative hidden rounded-xs text-minus2 my-2 p-1 pl-2 pr-6;
  }
  .uvFormFlash-info {
    background: #fcf6d7;
    color: black;
  }
  .uvFormFlash-error {
    @apply text-white bg-error;
  }
  .uvFormFlashClose {
    background-image: url(https://widget.uservoice.com/pkg/clients/_shared/close-533ae033797a2b0fc1a04a9f95305339fd1979b82f9463149c945fba04b4e873.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    font-size: 11px;
    margin-top: -8px;
    position: absolute;
    top: 18px;
    right: 12px;
    width: 16px;
    height: 16px;
    text-indent: -9000px;
    transition: none;
  }
  .uvFormFlashClose:focus,
  .uvFormFlashClose:hover {
    background-position: -16px 0;
  }
  .uvFormFlash-error .uvFormFlashClose {
    background-image: url(https://widget.uservoice.com/pkg/clients/_shared/close_reversed-3c97b9b229f8d2a5781e794c7a5db831c6b6fc8faee3fe48bbc5911c25a36f7e.png);
  }
  label.wrapped-checkbox {
    @apply flex;
  }
  label.wrapped-checkbox input[type="checkbox"] {
    @apply mr-2;
    margin-top: 3px;
  }
  .uvAjaxCheckbox {
    @apply mb-1;
    label {
      @apply flex leading-minus2;
      input {
        @apply mr-1;
      }
    }
  }
  .uvAjaxCheckbox-thinking .uvAjaxCheckboxInput {
    position: relative;
    &:before {
      content: '';
      position:absolute;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      top: 1px;
      left: -1px;
      border: 2px solid #999;
      border-bottom-color: transparent;
      animation: spin 1s infinite linear;
      text-indent: -9000px;

    }
  }
  .uvAjaxCheckbox-thinking .uvAjaxCheckboxInput input {
    visibility: hidden;
  }
}