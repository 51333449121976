wp-reset {

  @apply relative flex flex-shrink-0 text-normal leading-normal text-base;

  box-sizing: border-box;

  [hidden] {
    display: none !important;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
    line-height: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    color: inherit;
    text-decoration: inherit;
    border: 0 solid #ffffff;
  }

  a:visited, a:link {
    @apply no-underline;
    color: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply m-0 p-0;
    font-size: inherit;
    font-weight: inherit;
  }

  ul, ol {
    list-style: none;
  }

  button,
  input,
  select,
  textarea {
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: none;
    margin: 0;
    padding: 0;
  }

  input[type=checkbox] {
    appearance: checkbox;
  }

  input[type=radio] {
    appearance: radio;
  }

  *:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  summary:focus,
  label:focus,
  input:focus,
  textarea:focus,
  button:focus,
  [contenteditable]:focus,
  [tabindex]:focus,
  :focus + label .focus-ring,
  :focus + label[for]:not([no-focus-ring]) {
    @apply outline-none z-10;
    box-shadow: 0 0 0 2px theme('colors.focus-ring');
  }

  [aria-invalid]:focus,
  [aria-invalid]:focus + label .focus-ring,
  [aria-invalid]:focus + label[for]:not([no-focus-ring]) {
    @apply outline-none z-10;
    box-shadow: 0 0 0 2px theme('colors.focus-ring-error');
  }

  [focus-inner]:focus,
  [focus-inner]:focus + label[for]:not([no-focus-ring]) {
    @apply outline-none z-10;
    box-shadow: 0 0 0 1px theme('colors.focus-ring') inset;
  }

  ::-moz-focus-inner {
    border:0;
  }
}
