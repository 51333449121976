.site3 {

  .uvList {
    clear: both;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
  }
  .uvListItem {
    @apply break-words leading-normal;
    margin-bottom: 2px;
  }
  .uvListItem-selected a {
    color: inherit;
    font-weight: bold;
  }


  .uvNavListItem {
    background-position: 0 0;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }
  .uvNavListItemTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 5px;
    padding: 5px 0;
  }


  .uvNavListItem-search,
  .uvNavListItem-feedback,
  .uvNavListItem-contactus,
  .uvNavListItem-helpdesk,
  .uvNavListItem-home {
    padding-left: 34px;
  }
  .uvNavListItem-contactus {
    background-image: url(https://widget.uservoice.com/pkg/clients/_icons/icon.contact_us.24px-7a9210908718d7d16f2b426c9347dd3f9e19266ac964ed7efe11ab236122dee2.png);
  }
  .uvNavListItem-search {
    background-image: url(https://widget.uservoice.com/pkg/clients/_icons/icon.sidebar-search.24px-cb447bc79fabdb53518559bba8571a71d99c2494afe5f1bdc78e20bc568204c3.png);
  }
  .uvNavListItem-helpdesk {
    background-image: url(https://widget.uservoice.com/pkg/clients/_icons/icon.knowledge_base.24px-441ab9e8e2fa2342e84581d44a50828fad739ed6aca971bd43f1e805a2256064.png);
  }
  .uvNavListItem-feedback {
    background-image: url(https://widget.uservoice.com/pkg/clients/_icons/icon.idea.24px-ce3fc5b454d90a5c26c3973324d575bc1cd2b29fb711d302dcb899d6ee87d49f.png);
  }
  .uvNavListItem-home {
    background-image: url(https://widget.uservoice.com/pkg/clients/_icons/icon.home.24px-a5a7c99f93133cbec126a1444b5db301ca90c1bf9bd99bfb1554c64a1cb1ed54.png);
  }
  .uvListItem-noresults {
    background-image: none !important;
    color: #b8becc !important;
    font-weight: normal;
    font-family: Georgia, Serif;
    font-style: italic;
    padding-left: 0 !important;
  }
  .uvList-customLinks li {
    font-size: 14px;
    font-weight: bold;
    padding-left: 32px;
  }

  .uvListItem-newIdea {
    font-weight: bold;
  }

}