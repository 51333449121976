.site3 {

  .uvPagination {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }
  .uvPagination a,
  .uvPagination span,
  .uvPagination em {
    border-radius: 3px;
    display: inline-block;
    line-height: 1.75em;
    padding: 0 9px;
    margin-bottom: 5px;
  }
  .uvPagination .disabled {
    background-color: transparent;
    color: #666f80;
  }
  .uvPagination .current {
    color: #fff;
    font-weight: bold;
  }

  .uvPagination {
    margin-top: 40px;
    text-align: left;
  }
  .uvPagination a,
  .uvPagination span,
  .uvPagination em,
  .uvPagination .disabled {
    border-radius: 0;
    border: 1px solid #cfd7e6;
    font-size: 14px;
    font-style: normal;
    padding: 8px 16px;
  }
  .uvPagination .disabled {
    color: #d7d8d9;
  }

  .uvPagination .previous_page {
    display: none;
  }
}