.site3 {
  @apply text-normal leading-normal m-0 p-0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  .uvPageContainer {
    @apply relative text-normal leading-normal text-base;

    box-sizing: border-box;
  }
  wp-loading:not(.hydrated) {
    visibility: hidden;
  }

  body {
    @apply m-0
  }
  a {
    @apply no-underline;
    &:focus,
    &:hover {
      @apply underline;
    }
  }
  em,
  i {
    @apply italic;
  }
  strong,
  b {
    @apply font-bold;
  }
  ::selection {
    @apply bg-link-10;
  }
  mark {
    @apply bg-link-10;
  }

  /* Use this for elements that should only be visible to screen readers. */
  /* NOTE: aria-label should be used when providing additional context to form control or page section. */
  .sr-only {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    overflow: hidden !important;
  }

  .show-on-focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    padding: 16px;
    color: white;

    &:focus {
      z-index: 999;
      width: auto;
      height: auto;
      clip: auto;
    }
  }

  .firefox .uvForumFilter-statuses .uvFieldSelect {
    padding: 13px;
  }
  .uv-profile-page .uvPageTitle {
    margin-left: 0;
  }
  #uvFieldSelect-status {
    border-radius: 0;
    &:focus {
      outline: 3px solid transparent;
      outline-offset: -.4em;
    }
  }
  .uv-profile-page .uvModuleSection-search {
    display: none;
  }
  .uvIdeaShare {
    display: none;
  }

  ::selection {
    background-color: #dcf0ff;
  }

  .uv-home-page .uvBody {
    padding-bottom: 0;
  }

  .uv-forum .uvRow {
    *zoom: 1;
    overflow: visible;
  }
  .uv-forum .uvBody .uvRow:after {
    content: '';
    display: table;
    clear: both;
  }

  .utility-links {
    text-align: center;
    font-size: 13px;
    max-width: 800px;
  }
  .utility-links p {
    margin: 15px 30px 15px 30px;
  }
  .utility-links a {
    color: #777677;
  }

  .main-header {
    @apply overflow-visible mt-10;
  }
  @media (--viewport-md) {
    .main-header {
      @apply flex justify-between items-center mb-6;
      .uvPageTitle {
        @apply w-1/2;
      }
    }
  }

  .main-header .uvModule-message p:last-child {
    margin-bottom: 0;
    color: #777677;
  }

  .uvModule-message {
    font-size: inherit;
    @apply float-none mb-2;
  }

  @media (--viewport-sm) {
    .uvModule-message {
      @apply m-0;
    }
  }

  @media (--viewport-md) {
    .uvModule-message {
      @apply mr-4;
    }
  }

  .uv-home-page .uvModule-mainSidebar {
    background-color: transparent;
    box-shadow: none;
    float: none;
    padding: 0;
    margin: 0;
  }

  .uvList-categories .uvListItem-categoriesTitle,
  .uvList-categories a {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  .uvList-categories .uvStyle-count {
    display: block;
    background: none;
    color: white;
    font-size: 13px;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .uvList-categories .uvListItem {
    margin: 0;
    position: relative;
    &:last-child a {
      border-radius: 0 0 3px 3px;
    }
  }

  .uvList-categories .uvListItem-categoriesTitle {
    padding: 21px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 0 0;
  }

  .uvList-categories .uvListItem a {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 11px 21px 28px;
    margin: 0;
    text-decoration: none;
  }
  .uvList-categories .uvListItem.uvListItem-selected a {
    font-weight: 600;
  }

  .uvList-categories .uvListItem span {
    position: absolute;
    left: 21px;
    bottom: 15px;
  }

  .uv-suggestion-page .uvForm-comments {
    margin-bottom: 0;
  }

  .uvUserActionHeader,
  .uvStyle-meta {
    font-size: 13px;
  }

  .uvCol-8 .uvPageHeader {
    margin-bottom: 100px;
  }

  .uvTab-createidea-group .uvTabSpace {
    position: relative;
    @media (--viewport-sm) { top: 9px; }
  }

  .uvList-comments {
    padding-top: 40px;
  }
  .uvList-comments .uvListItem {
    margin: 0 -40px;
    padding: 42px 40px 10px;
    border-top: 2px solid #f5f6f7;
  }

  .uvIdeaComments {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    border-top: 10px solid #f5f6f7;
  }
  .uvIdeaComments .uvUserAction-comment {
    @apply m-0;
    @media (--viewport-sm) { margin: 0 0 30px 60px; }
  }

  .uvField-comment .uvFieldTextarea:focus {
    outline: 3px solid transparent;
    outline-offset: 0.5em;
  }

  .uvUserAction-admin-response,
  .uvUserAction-comment,
  .uvUserAction-suggestIdea,
  .uvUserAction-vote {
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .uvListItem-noresults {
    font-family: inherit;
  }

  .uvField-idea wp-icon {
    width: 20px;
    right: 20px;
    position: absolute;
  }

  .uvField-idea #suggestion_title:focus {
    outline: 3px solid transparent;
    outline-offset: .8em;
  }

  .uvField-idea.uvField-hasvalue wp-icon {
    display: none!important;
  }

  .uvField-idea.uvField-hasvalue {
    background-image: none;
  }

  .uvList-ideas .uvIdeaDescription {
    color: #536673;
    font-size: 16px;
  }


  .uvFiltersExplanation {
    position: relative;
    top: 30px;
  }

  .uvNotice {
    margin-top: 40px;
  }

  wp-translate-menu {
    top: 100px;
  }
}
