@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=fallback');

@import 'site3-partials/resets.pcss';
@import 'site3-partials/attachments.pcss';
@import 'site3-partials/fancybox.pcss';
@import 'site3-partials/field.pcss';
@import 'site3-partials/form.pcss';
@import 'site3-partials/forum.pcss';
@import 'site3-partials/idea.pcss';
@import 'site3-partials/layout.pcss';
@import 'site3-partials/list.pcss';
@import 'site3-partials/misc.pcss';
@import 'site3-partials/module.pcss';
@import 'site3-partials/navlist.pcss';
@import 'site3-partials/new-profile.pcss';
@import 'site3-partials/pages.pcss';
@import 'site3-partials/pagination.pcss';
@import 'site3-partials/search.pcss';
@import 'site3-partials/sidebar.pcss';
@import 'site3-partials/signin.pcss';
@import 'site3-partials/style.pcss';
@import 'site3-partials/tab.pcss';
@import 'site3-partials/tooltip.pcss';
@import 'site3-partials/typeset.pcss';
@import 'site3-partials/user.pcss';
@import 'site3-partials/voting.pcss';
@import 'site3-partials/scoring.pcss';
@import 'site3-partials/button.pcss';
@import 'site3-partials/suggestion-status.pcss';
@import 'site3-partials/footer.pcss';
@import 'site3-partials/container.pcss';
@import 'site3-partials/upload.pcss';
@import 'site3-partials/flag.pcss';

@import 'site3-partials/base.pcss';
