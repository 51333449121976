.site3 {
  .uvIdeaComments .uvUserAction-comment {
    margin-bottom: 60px;
  }
  .uvIdea-small .uvUserAction {
    margin-top: 20px;
  }
  .uvUserActionHeader .uvUserBadge {
     @apply absolute -ml-8 !important;
     margin-top: -8px;
  }
  .uvUserActionHeader .fn {
    font-weight: bold;
  }
  .uvUserActionHeader a:focus .fn,
  .uvUserActionHeader a:hover .fn {
    text-decoration: underline;
  }
  .uvUserActionBody {
    color: #292c33;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .uvUserActionFooter {
    color: #8f9bb3;
    font-size: 11px;
  }
  .uvUserActionFooter a {
    color: inherit;
  }
  .uvUserActionAttachments {
    height: 100%;
    margin-bottom: -10px;
    overflow: auto;
  }
  .uvRememberMeThinking {
    color: green;
  }
  .uvLink-thinking:after {
    content: url(https://widget.uservoice.com/pkg/shared/uv-button-thinking-2dc17322196be8f3407799005fe57b0c99d2cd2ddb630922d6063a44815a2c66.gif);
  }
  .uvLink-done {
    color: green;
  }
  .uvRememberMeError {
    color: red;
  }
  .uvPageTitle .uvUserBadge {
    margin-top: -12px;
  }
  .uvList-ideas .uvUserBadge,
  .uvUserAction-suggestIdea .uvUserBadge,
  .uvUserAction-vote .uvUserBadge {
    margin: -1px 0 0 -20px;
  }
  .uvUserBadgeLabel {
    background-color: #8f9bb3;
    display: none;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    line-height: 2em;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.75) 0 -1px 0;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
  }
  .uvList-ideas .uvUserBadgeLabel,
  .uvUserAction-suggestIdea .uvUserBadgeLabel {
    display: none;
  }
  .uvUserBadgePhoto,
  .uvUserBadge .photo,
  .uvUserBadge .avatar {
    display: none;
    height: 40px;
    max-height: 40px;
    width: 40px;
    max-width: 40px;

    @media (--viewport-sm) { display: block; }
  }
  .uvList-ideas .uvUserBadgePhoto,
  .uvList-ideas .uvUserBadge .photo,
  .uvList-ideas .uvUserBadge .avatar {
    height: 40px;
    max-height: 40px;
    width: 40px;
    max-width: 40px;
    display: none;

    @media (--viewport-sm) { display: block; }
  }
  .uvAdminOnly {
    display: none;
  }
  .uv-user-admin .uvAdminOnly-inline {
    display: inline;
  }
  .uvUserOnly {
    display: none;
  }
  .uv-user .uvUserOnly-inline {
    display: inline;
  }
  .uv-user .uvUserOnly-block {
    display: block;
  }
  .uv-user .uvAnonymousOnly {
    display: none;
  }

  .uv-create-user-page .uvField {
    overflow: visible;
  }

  /* Shows user avatar for action (forums page) */
  .uvUserAction-comment,
  .uvUserAction-admin-response,
  .uvUserAction-suggestIdea,
  .uvUserAction-vote {
    position: relative;
    .uvUserBadge {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  /* Shows user avatar for action (sugg details page) */
  .uvUserProfile .uvUserBadge,
  .uvIdea-show .uvUserBadge {
    display: inline-block;
  }

  .uvUserProfile .uvPageHeader .uvUserBadge {
    display: none;
  }

  .uvUserProfile .uvPageHeader {
    @apply px-3;
    @media (--viewport-sm) { @apply px-0 mb-20; }
  }


  .uvSoftGrayBackground {
    @apply absolute w-full bottom-0;
    background-color: #F5F6F7;
    top: 265px;
    z-index: -1;
  }

  .uvUserSettingsDivider {
    @apply my-5 w-full opacity-25 relative;
    height: 1px;
    background-color: #F5F6F7;
  }

  .uvUserProfile .uvFiltersExplanation {
    @apply text-normal mb-2 p-3 top-0 static border-t-4 rounded-t-sm bg-white;
    @media (--viewport-sm) { @apply p-5; }
  }

  .uvUserProfile .uvIdea-list {
    @apply mb-2 px-0 pb-1;

    .uvIdeaHeader {
      @apply px-3;
      @media (--viewport-sm) {
        @apply pr-6;
        padding-left: 160px;
       }
      min-height: 70px;
    }

    .uvIdeaVoteBadge {
      @apply ml-3;
    }

    .uvIdeaMeta {
      @apply mt-6 mb-4 pt-3 px-3;
      @media (--viewport-sm) { @apply pt-4 px-6; }
      border-top: 2px solid #F5F6F7;
      & > a:first-of-type {
        @apply font-semibold text-plus1 leading-plus1;
        color: black;
      }
    }

    .uvScorer {
      @apply px-3;
      @media (--viewport-sm) { @apply px-6; }
    }

    .uvUserAction-comment,
    .uvUserAction-admin-response,
    .uvUserAction-suggestIdea,
    .uvUserAction-vote {
      @apply px-3;
      @media (--viewport-sm) { @apply pr-6 pl-15; }
      .uvUserTitle {
        @apply hidden;
      }
      .uvUserBadge {
        margin-left: 0px !important;
        left: 36px;
        top: -5px;
      }
      .uvStyle-meta {
        color:inherit;
        &:before {
          content: "on";
        }
        & > .uvStyle-separator {
          @apply hidden;
        }
      }
      .vcard .fn {
        @apply uppercase;
      }
      .uvUserActionBody, .uvUserActionEdit {
        @apply mt-1 mb-5;
        .typeset p {
          @apply text-normal leading-normal;
        }
      }
    }
    .uvUserAction-vote {
      @apply hidden;
    }
  }
}