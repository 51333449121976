.site3 {
  .uvModule {
    border: 1px solid #cfd7e6;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 1px;
    clear: both;
    float: left;
    font-size: 12px;
    margin-bottom: 10px;
    padding-top: 19px;
    padding-right: 19px;
    padding-left: 19px;
    width: 100%;
  }
  .uvModule-message {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 14px;
    padding: 0;

    .uvModule-welcome-text {
      @apply m-0 leading-normal mb-20;
      color: #536673;
    }
  }
  .uvModule-session {
    @apply w-auto;
    @media (--viewport-sm) {
      .uvSidebar & {
        width: 600px;
        margin-left: -400px;
      }
    }
  }
  .main-header-search {
    @apply flex justify-center;
    .uvModuleHeader {
      display: none;
    }

    @media (--viewport-md) {
      float:right;
      @apply block;
    }
  }
  .uvModuleHeader {
    color: #666f80;
    margin-bottom: 10px;
    position: relative;
  }
  .uvModule-toggle .uvModuleHeader {
    cursor: pointer;
  }
  .uvModuleHeader .forum-title {
    font-size: 16px;
    margin-bottom: 10px;
    position: relative;
    display: block;
    padding: 0 25px;
    font-weight: 600;

  }
  .uvModuleHeaderEtc {
    font-size: 11px;
    line-height: 18px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
    right: 0;
  }
  .uvModule-toggle .uvModuleHeader .uvModuleTriggerTwirl {
    background-image: url(https://widget.uservoice.com/pkg/clients/site2/twirl.16px-5fb04e3d2adab691b0401b58b14f7560c34b7dfd4603bf784b61d2e68df45817.png);
    background-position: 0 0;
    float: left;
    height: 16px;
    margin-top: 4px;
    margin-right: 5px;
    width: 16px;
  }
  .uvModule-toggle .uvModuleHeader:hover .uvModuleTriggerTwirl {
    background-position: -20px 0;
  }
  .uvModule-toggle-collapsed .uvModuleHeader .uvModuleTriggerTwirl {
    background-position: -120px 0;
  }
  .uvModule-toggle-collapsed .uvModuleHeader:hover .uvModuleTriggerTwirl {
    background-position: -100px 0;
  }
  .uvModule-toggle .uvModuleHeader:active .uvModuleTriggerTwirl,
  .uvModule-toggle-trigger .uvModuleHeader:active .uvModuleTriggerTwirl {
    background-position: -60px 0;
  }
  .uvModuleHeader p {
    line-height: 1.3em;
    margin-bottom: 0.7em;
  }
  .uvModuleIdeaHeader {
    font-size: 14px;
  }
  .uvModuleSection {
    margin-bottom: 20px;
  }
  .uvModule-mainSidebar .uvModuleSection:last-child {
    margin-bottom: 15px;
  }
  .uvModuleSectionHeader {
    font-size: 11px;
    margin-bottom: 10px;
  }
  .uvModuleSectionHeaderMeta {
    font-size: 11px;
    font-weight: normal;
  }
  .uvModule-session .vcard {
    @apply text-base;
  }
  .uvModule-session .vcard a {
    @apply flex items-center;
    @media (--viewport-sm) {
      @apply flex-row-reverse;
    }
  }
  .uvModule-session .uvUserBadge {
    @apply w-5 h-5 mr-2 flex-shrink-0;
    @media (--viewport-sm) {
      @apply flex-row-reverse ml-2 mr-0;
    }
    img {
      @apply w-full h-full max-w-full max-h-full block;
    }
  }

  .uvModule-session .fn {
    @apply font-bold uppercase tracking-wide -mt-3;
  }

  .uvModule-session .uvUserNav {
    @apply -mt-5 ml-12;
    @media (--viewport-sm) {
      @apply mr-12 text-right;
    }
  }
  .uvUserBadge {
    @apply rounded-full overflow-hidden;
  }

  .uvModule-session .uvUserTitle {
    color: #8f9bb3;
  }
  .uvModule-session .uvUserNotice {
    margin-bottom: 5px;
  }
  .uvModule-session .uvUserNotice a {
    color: #b30000;
  }
  .uvModule-session .uvUserNotice a[data-dismiss] {
    color: inherit;
  }
  .uvModuleSection-signin {
    @apply text-minus1 flex flex-col;
  }
  .uvModule-session .uvStyle-signout {
    float: right;
    font-size: 11px;
    margin-left: 1em;
  }
  .uvModule-session .uvStyle-signin a,
  .uvModule-session .uvStyle-signin-provider a,
  .uvModule-session .uvStyle-signout a {
    @apply font-bold capitalize text-link;
    margin: 0 2px;
  }
  .uvModule-session .uvjSigninStandalone,
  .uvModule-session uv-auth:not(.uv-auth-sso) {
    display: none;
    left: 2%;
    right: 2%;
    max-width: 95%;
    padding: 0 32px 44px 18px;
    @media (--viewport-sm) {
      @apply left-auto;
    }
  }
  .uvModule-session uv-auth:not(.uv-auth-sso) {
    @apply absolute z-10;
    width: 425px;
  }
  .uvModule-session .uvjSigninStandalone {
    @apply absolute p-4 rounded-sm shadow-lg bg-white z-10;
    width: 400px;
  }
  .uvModule-session .uvUserboxStats,
  .uvModule-session .uvUserboxStat {
    float: left;
    font-size: 11px;
  }
  .uvModule-session .uvUserboxStats a {
    color: inherit;
  }
  .uvModule-session .uvUserboxStat {
    margin-right: 1em;
  }
  .uvModule-featuredForum {
    box-sizing: border-box;
    float: left;
    font-size: 11px;
    margin-bottom: 32px;
    padding: 20px 20px 0 20px;
    width: 100%;
  }
  .uvModule-featuredForum .uvModuleSection-featuredIdeas {
    box-sizing: border-box;
    float: left;
    margin-right: 3.8%;
    width: 48.1%;
  }
  @media handheld, only screen and (max-width: 767px) {
    .uvModule-featuredForum .uvModuleSection-featuredIdeas {
      float: none;
      margin-right: 0;
      width: auto;
    }
  }
  .uvModule-featuredForum .uvModuleSection-recentlyUpdatedIdeas {
    box-sizing: border-box;
    float: left;
    width: 48.1%;
  }
  @media handheld, only screen and (max-width: 767px) {
    .uvModule-featuredForum .uvModuleSection-featuredIdeas,
    .uvModule-featuredForum .uvModuleSection-recentlyUpdatedIdeas {
      float: none;
      width: auto;
    }
  }
  .uvModule-featuredForum .uvModuleTitle,
  .uvModule-multipleForums .uvModuleTitle,
  .uvModule-knowledgebase .uvModuleTitle {
    background: url(https://widget.uservoice.com/pkg/icons/classic/32/11-db5f36de0d5d022e575c9581fa1b37303d024265642da55b34ebbb1d2c01eeb8.png)
      0 0 no-repeat;
    line-height: 32px;
    padding-left: 42px;
  }
  .uvModule-knowledgebase .uvModuleTitle {
    background-image: url(https://widget.uservoice.com/pkg/icons/classic/32/2-beae9eda8667d99a50c895f22e7a504bbdf93a7db7b884be90adae09fedca393.png);
  }
  .uvListMore {
    font-size: 11px;
    margin-left: 21px;
  }
  .uvModule-featuredForum .uvListItem-postNewIdea {
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
    margin-left: 65px;
  }
  .uvModule-featuredForum .uvModuleSectionHeader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .uvUserSettingsAdminNotifications {
    @apply mt-3 !important;
  }
  .uvUserSettingsFieldset {
    @apply mt-4 leading-minus2;
    &:first-of-type {
      @apply mt-0;
    }
  }
  .uvUserSettingsFieldsetLegend {
    @apply text-plus1 mb-3 font-medium leading-plus1 w-full;
    & > a {
      @apply w-full py-2;
    }
  }
  .uvUserSettingsFieldsetLegendNormal {
    @apply text-minus1 mb-2;
  }

  .uvModuleSection-settings {
    @apply text-white mx-4;

    @media (--viewport-sm) { @apply mx-6; }

    .uvAjaxCheckboxLabel, .uvUserSettingsMinorText {
      color: white;
    }

    .uvUserSettingsPassword {
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .uvModuleSection-settings .uvField {
    @apply bg-white text-minus1 leading-minus1 mx-0 mt-0 mb-5 pt-2 pb-2;
    height: 51px;
    * {
      @apply leading-minus1;
    }
  }

  .uvModule-powered_by {
    background: url(https://widget.uservoice.com/images/clients/site2/powered_by_background.png)
      50% 50% no-repeat;
    padding: 0;
  }
  .uvModule-powered_by li {
    background-image: url(https://widget.uservoice.com/images/icons/powered_by_bullhorn.24px.png);
    background-repeat: no-repeat;
    background-position: 14px 50%;
    display: block;
    font-size: 12px;
    font-weight: bold;
    padding: 14px 19px 14px 44px;
  }
  .uvModule-cookie-banner {
    background-color: black;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
  }
  .uvModule-cookie-banner .content {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 3px 0;
    min-height: 35px;
    padding: 0 12px;
    text-align: center;
  }
  .uvModule-cookie-banner .content a {
    color: #4f92ff;
    padding-left: 3px;
  }

  .uvModule-mainSidebar .uvStyle-count-label {
    position: absolute;
    text-indent: -9999px;
  }
  .uvModuleSection-helpdesk-sections,
  .uvModuleSection-feedback-forums {
    margin-bottom: 15px;
  }
  .uvModule-mainSidebar .uvNavListItem-standardHeader {
    font-size: 14px;
    font-weight: bold;
    color: #6f675e;
  }

  .uvModule-mainSidebar .uvListMore {
    font-size: 12px;
    font-weight: normal;
  }
  .uvModule-mainSidebar .uvListMore {
    padding-left: 12px;
  }
  .uvModule-mainSidebar .uvListItemHeader {
    display: inline;
  }
  .uvModule-feedback .uvStyle-count,
  .uvModule-mainSidebar .uvStyle-count {
    color: rgba(40, 50, 60, 0.7);
    background: #e7eaf2;
    display: inline;
    padding: 0 0.5em;
    margin-left: 3px;
    line-height: 14px;
    font-size: 11px;
    border-radius: 7px;
    text-align: center;
    min-width: 1em;
  }

  .uvModule-featuredForum .uvList {
    font-size: 14px;
  }
  .uvModule-featuredForum .uvList-forums-2col {
    column-count: 2;
    column-gap: 20px;
  }
  .uvModule-featuredForum .uvListItem {
    column-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 15px;
  }

  @media handheld, only screen and (max-width: 767px) {
    .uvModule-featuredForum .uvList-forums-2col {
      column-count: auto;
    }
  }
  .uvModule-featuredForum .uvListItemHeader {
    line-height: 18px;
    padding: 3px 0;
  }
  .uvModule-mainSidebar .uvList-ideas {
    margin-bottom: 20px;
  }
  .uvModule-mainSidebar .uvList-ideas li {
    position: relative;
    margin: 0 5px 6px 59px;
  }
  .uvModule-mainSidebar .uvIdeaTitle {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
  }
  .uvModule-mainSidebar .uvVoterMode-voteable {
    position: absolute;
    top: 5px;
    left: -59px;
  }
  .uvModule-feedback .uvModuleSectionHeader,
  .uvModule-helpdesk .uvModuleSectionHeader {
    font-size: 14px;
    font-weight: bold;
  }
  .uvModule-feedback .uvHeader-previousForum {
    font-weight: normal;
  }
  .uvModule-feedback .uvModuleSectionHeader a,
  .uvModule-helpdesk .uvModuleSectionHeader a {
    color: inherit;
  }
  .uvModule-feedback .uvForumVotesRemaining {
    color: #fff;
    font-size: 12px;
    margin: -13px 0 16px -25px;
    position: relative;
    display: block;
  }

  .uv-primaryColor-light .uvModule-feedback .uvForumVotesRemaining {
    color: #000;
  }
  .uvModuleSection-search .uvFieldEtc-thinking,
  .uvModuleSection-search .uvFieldEtc-reset,
  .uvModule-mainSidebar .uvFieldEtc-thinking,
  .uvModule-mainSidebar .uvFieldEtc-reset {
    right: 32px;
  }
  .uvModuleSection-search {
    margin-bottom: 20px;
  }
  .uvModule-mainSidebar .uvStyle-status {
    float: right;
  }

  .uvModule {
    border: 0;
    box-shadow: none;
  }
  .uvModule-settings {
    @apply p-0 pt-3;
    @media (--viewport-sm) { @apply py-6; }
  }

  .uvModuleSection-search {
    position: relative;
    margin: 0;

    @media (--viewport-md) {
      width: 250px;
    }

    @media (--viewport-lg) {
      width: 320px;
    }
  }

  .uvModule-feedback.uvModule,
  .uvModule-mainSidebar.uvModule {
    box-shadow: none;
    @apply rounded-none p-0 w-auto;

    @media (--viewport-md) {
      @apply block w-full;
    }
  }

  .uvModule-mainSidebar.uvModule {
    background-color: transparent;
  }

  .uvModule-feedback {
    background-color: transparent;
  }


  .uvModule-feedback .uvModuleSectionHeader,
  .uvModule-feedback .uvForumVotesRemaining,
  .uvModule-feedback .uvListItem-ideaTrigger,
  .uv-forum-page .uvModule-mainSidebar .uvModuleSection-search,
  .uv-suggestion-page .uvModule-mainSidebar .uvModuleSection-search,
  .uvModule-mainSidebar .uvNavListItem-contactus,
  .uvModule-mainSidebar .uvNavListItem-helpdesk,
  .uvModule-mainSidebar .uvNavListItem-home {
    display: none;
  }

  .uvModuleSection-search form {
    position: relative;
    z-index: 2;
  }

  .uvModuleSection-search .uvFieldButton:active,
  .uvModuleSection-search .uvFieldButton:focus {
    background-color: black;
  }
  .uvModuleSection-search .uvFieldEtc-thinking {
    display: none;
  }
  .uvModuleSection-search .uvFieldEtc-reset {
    display: none;
  }

  .uvModuleSection-search .uvListItem {
    @apply font-medium text-minus1 leading-minus2 mb-2;
  }
  .uvModuleSection-search .uvListItem a {
    color: #777677;
  }

  .uvMasthead {
    @apply mt-6;
    & > .uvRow {
      @apply flex justify-between flex-wrap;
    }
  }

  .uvMastheadWrapper {
    .uvMastheadLogo {
      max-width: 180px;
    }
  }
}
