.site3 {

  .uvSidebar {
    position: relative;
  }

  .uvSidebar.uvForumSidebar {
    display: none;

    @media (--viewport-md) {
      display: block;
    }

    &.enhanced-portal-accessibility {
      display: block;
    }
  }

  .uvSidebar .uvStyle-status,
  .uvNavListItem-search .uvStyle-status {
    font-size: 10px;
    line-height: 15px;
    padding: 0 1ex;
    border-radius: 3px;
    position: relative;
    margin-left: 5px;
  }
  .uvModule-featuredForum .uvStyle-status,
  .uvList-recentlyUpdatedIdeas .uvStyle-status {
    font-size: 10px;
    line-height: 15px;
    padding: 0 1ex;
    border-radius: 3px;
    position: relative;
    margin-left: 0;
    margin-right: 5px;
  }
  .uvNavListItemSubtitle {
    font-size: 11px;
    font-weight: bold;
    color: #666f80;
    padding-bottom: 8px;
  }

  .uvSidebar .uvModule-elo {
    margin-bottom: 10px;
  }
}