.site3 {

  .uvPasswordReset-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    transition: opacity 400ms ease-in-out;
  }
  .uvPasswordReset-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
    max-width: 90vw;
    height: auto;
    color: black;
    z-index: 10000;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    background: white;
    border-radius: 3px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 0 1px, rgba(0, 0, 0, 0.25) 0 1px 24px,
      rgba(0, 0, 0, 0.5) 0 1px 50px;
  }
  .uvPasswordReset-dialog.popup {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    border-radius: 0;
  }
  .uvPasswordReset-dialog.popup .uvPasswordReset-dialog-header {
    display: none;
  }
  .uvPasswordReset-dialog.popup .uvPasswordReset-dialog-content {
    padding: 0;
  }
  .uvPasswordReset-dialog.popup .uvPasswordReset-dialog-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .uvPasswordReset-dialog-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q0ZDdkYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Q0ZDdkYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #d4d7da),
      color-stop(100%, #d4d7da)
    );
    background: -moz-linear-gradient(#d4d7da 0%, #d4d7da 100%);
    background: -webkit-linear-gradient(#d4d7da 0%, #d4d7da 100%);
    background: linear-gradient(#d4d7da 0%, #d4d7da 100%);
    background-color: #f9fafb;
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 100px 1px;
    padding: 20px 25px;
    height: 16px;
  }
  .uvPasswordReset-dialog-close-button {
    position: absolute !important;
    right: 20px;
    top: 20px;
    z-index: 10;
    color: #8a8f99;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: inherit;
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  .uvPasswordReset-dialog-content {
    padding: 25px 25px;
    overflow-y: auto;
  }
  .uvPasswordReset-dialog-buttons {
    text-align: right;
  }
  .uvFieldEtc {
    background-color: transparent !important;
  }
  .uvField-authentication img.password {
    display: none;
  }
  .uvSignin {
    @apply text-base-50 text-normal mb-2 z-10 relative;
  }
  .uvSigninLegend {
    display: none !important;
  }
  .uvSignin .uvField-first {
    border-radius: 3px;
    margin-top: 0;
  }
  .uvSignin-display_name .uvField {
    border-radius: 3px;
  }
  .uvSigninDescription {
    margin-bottom: 8px;
  }
  .uvSocialAuthOnly {
    display: flex;
  }
  .uvSocialAuthOnly li {
    margin-right: 4px;
  }
  .uvSocialAuthOnly li:last-child {
    margin-right: 0;
  }
  .uvSocialAuthOnly img {
    width: 32px;
    height: 32px;
  }
  .uvSignin,
  .uvSignin-uv-auth {
    .uvField-email {
      display: block;
    }
    .uvField-authentication {
      display: none;
    }
    .uvField-third_party {
      display: none;
    }
    .uvField-display_name {
      display: none;
    }
    .uvField-logged_in {
      display: none;
    }
    /* TODO: Is this reset stuff needed? Don't see it in signin form */
    .uvFieldEtc-reset {
      display: block;
      background-image: url(https://widget.uservoice.com/pkg/clients/_shared/signin_reset-af449156e0c303a0a8caeff6de0739e5158fb1228edcb397dabcec0166eac0db.png);
      cursor: pointer;
      width: 31px;
      text-indent: -9000px;
      right: 1px;
      border: 0;
      margin-top: -8px;
      &:hover, &:focus {
        background-image: url(https://widget.uservoice.com/pkg/clients/_shared/signin_reset_hover-dfb4355ca1ce0e51fe15742bb81000f8570c6977ea5caee4ae542f65259da106.png);
      }
    }
  }
  .uvFieldEtc-unprotected {
    display: none;
    background-image: url(https://widget.uservoice.com/pkg/clients/_shared/signin_unprotected-0bae4a9e19e328560af642a0afae0c875dbc1a599817d799167a7b5a47158186.png);
    width: 16px;
    text-indent: -9000px;
  }
  @keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
  }
  .uvFieldEtc-thinking {
    display: none;
    box-sizing: border-box;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    border: 2px solid #999;
    border-bottom-color: transparent;
    padding: 0;
    margin: 0;
    margin-top: -8px;
    animation: spin 1s infinite linear;
    text-indent: -9000px;
  }
  .uvFieldEtc-email_invalid {
    display: none;
    color: #b30000;
  }
  .uvFieldEtc-password_invalid {
    display: none;
    color: #b30000;
  }
  .uvFieldEtc-create_password {
    display: none;
    position: relative;
  }
  .uvFieldEtc-third_party {
    display: none;
    float: left;
  }
  .uvSignin-thinking .uvFieldEtc-thinking,
  .uvField-thinking .uvFieldEtc-thinking {
    display: block;
  }
  .uvSignin-thinking .uvFieldEtc-reset {
    display: none;
  }
  .uvSignin .uvFielderror .uvFieldEtc-email_invalid {
    display: block;
  }
  .uvSignin .uvField-error .uvFieldEtc-password_invalid {
    display: block;
  }
  .button-states-language {
    display: none;
  }
  .submit_region {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .uvVoterSigninArea {
    padding: 10px;
  }
  .uvFieldEtc-sso_auth {
    @apply flex float-right;
    .icon-only-label {
      @apply m-0 ml-1;
    }
  }
  .uvSignin .uvField-display_name {
    overflow: visible;
  }
  .uvField-consent,
  .uvField-terms_implicit {
    display: none;
    position: relative;
    left: 0;
    height: auto;
    font-size: 11px;
    background: none !important;
    border: none !important;
    box-shadow: inset 0 0 !important;
    color: #666 !important;
    margin-top: 10px !important;
    padding: 0 3px !important;
  }
  .label-note {
    display: block;
    color: #666;
    font-size: 11px;
    margin-bottom: 0.7em;
    line-height: 1.3em;
  }
  .label-note.inline-block {
    display: inline-block;
  }
  .ie7 .label-note.inline-block {
    display: inline;
    width: auto;
  }
  .confirmation-email-note {
    display: none;
    padding: 10px 0 0 5px;
  }
  .uvSignin-display_name .confirmation-email-note {
    display: block;
  }
  .uvSignin-display_name .submit_region .label-note,
  .uvSignin-third_party_pw .submit_region .label-note,
  .uvSignin-unprotected .submit_region .label-note,
  .uvSignin-existing_account .submit_region .label-note {
    display: none !important;
  }
  .uvField-consent .uvField,
  .uvField-terms_implicit .uvField {
    overflow: visible;
  }
  .submit_region .uvFieldEtc-submit-button {
    margin-left: 1em !important;
  }
  .submit_region .uvFieldEtc-cancel-page {
    display: none;
    cursor: pointer;
    color: #007dbf;
    background: none;
    border: 0;
    font-size: 10px;
    margin-left: 5px;
    text-decoration: underline;
  }
  .uvSignin .uvField-consent {
    border-radius: 0 !important;
    border-top: 1px solid #ddd !important;
    padding-top: 18px !important;
    margin-top: 18px !important;
    & + .uvField-consent {
      border: none !important;
      padding: 0 !important;
    }
  }
  .uvSignin-display_name .uvField-consent,
  .uvSignin-display_name .uvField-terms_implicit {
    @apply block text-minus1 leading-minus2 pl-2;
  }
  .uvSignin-checking .uvField-email {
    display: none;
  }
  .uvSignin-prestine .uvFieldEtc-reset {
    display: none;
  }
  .uvSignin-prestine .uvFieldEtc-providers {
    display: block;
  }
  .uvSignin-prestine .error .uvFieldEtc-email_invalid {
    display: none;
  }
  .uvSignin-prestine .error .uvFieldEtc-password_invalid {
    display: none;
  }
  .uvSignin-password .uvSignin-gdpr-existing-user .gdpr-consent,
  .uvSignin-password .uvField-authentication {
    display: block;
  }
  .uvSignin-third_party .uvField-authentication,
  .uvSignin-create_password .uvField-authentication {
    display: block;
  }
  .uvSignin-third_party .uvFieldEtc-create_password,
  .uvSignin-create_password .uvFieldEtc-create_password {
    display: block;
  }
  .uvSignin-third_party .uvFieldEtc-forgot_password,
  .uvSignin-create_password .uvFieldEtc-forgot_password {
    display: none;
  }
  .uvSignin-third_party .uvFieldEtc-third_party {
    display: block;
  }
  .uvSignin-create_password .submit_region {
    display: none;
  }
  .uvSignin-third_party_reaffirm_terms .uvField-authentication,
  .uvSignin-third_party_pw .uvField-authentication {
    @apply block;
  }
  .uvSignin-third_party_reaffirm_terms .uvFieldEtc-third_party,
  .uvSignin-third_party_pw .uvFieldEtc-third_party {
    display: block;
  }
  .uvSignin-display_name .uvField-display_name {
    display: block;
    margin-bottom: 5px;
  }
  .uvSignin-unprotected .uvSignin-gdpr-existing-user .gdpr-consent {
    display: block;
  }
  .uvSignin-unprotected .uvFieldEtc-reset {
    display: none;
  }
  .uvSignin-logged_in .uvField-email {
    display: none;
  }
  .uvSignin-logged_in .uvField-consent,
  .uvSignin-logged_in .uvField-terms_implicit {
    display: none;
  }
  .uvSignin-logged_in .confirmation-email-note {
    display: none;
  }
  .uvSignin-logged_in .uvField-provider {
    display: none;
  }
  .uvSignin-logged_in .uvField-logged_in {
    display: block;
  }
  .uvSignin-logged_in .uvField-social-only {
    display: none;
  }
  .auth-only {
    @apply text-minus1 text-base font-medium;
  }
  .uvSignin-logged_in .auth-only {
    display: none;
  }
  .uvSignin-display_name .uvFieldEtc-password_reset,
  .uvSignin-unprotected .uvFieldEtc-password_reset {
    display: none;
  }
  .uvSignin-logged_in .submit_region {
    flex-direction: row;
  }
  .uvSignin-logged_in .submit_region .uvFieldEtc-submit-button {
    margin-left: 0;
    margin-right: 1em;
  }
  .uvSignin-prestine.uvSignin-existing_account .uvField-authentication {
    display: block;
  }
  .uvSignin-reaffirm_terms .uvField-authentication,
  .uvSignin-email_edit.uvSignin-existing_account .uvField-authentication {
    display: block;
  }
  .uvSignin-third_party_reaffirm_terms .tos-consent,
  .uvSignin-unprotected_reaffirm_terms .tos-consent,
  .uvSignin-reaffirm_terms .tos-consent,
  .uvSignin-reaffirm_terms.uvSignin-existing_account .tos-consent {
    display: block;
  }
  #consent-modal-overlay.modal-overlay {
    z-index: 100000;
  }
  #consent-modal.modal {
    z-index: 100001;
  }
  .modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100000;
  }
  .modal {
    display: none;
    flex-direction: column;
    position: fixed;
    z-index: 100001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .modal #new-user-consent form {
    padding: 28px 0;
  }
  .modal .modal-content {
    background-color: #fefefe;
    color: #1a0e00;
    display: none;
    margin: auto;
    padding: 40px;
    border: 1px solid #888;
    width: 90%;
    max-width: 700px;
    font-size: 1.1em;
  }
  .modal .modal-content h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  .modal .modal-content p {
    padding: 16px 0;
  }
  .modal .modal-content button {
    margin-top: 8px;
  }
  .modal .modal-content a {
    text-decoration: underline !important;
    color: #0a8cc6;
  }
  .modal .modal-content form {
    display: flex;
    flex-direction: column;
  }
  .modal .modal-content form .warning-text {
    color: #e56245;
  }
  .modal .modal-content form label {
    display: flex;
    margin-bottom: 16px;
  }
  .modal .modal-content form label:last-child {
    margin-bottom: 0;
  }
  .modal .modal-content form input {
    margin-right: 12px;
  }
  .modal button {
    color: #fff;
    padding: 4px 15px;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .modal button.primary {
    background: #0a8cc6 linear-gradient(#0b9dde, #0a8cc6);
    background-clip: border-box;
    border: 1px solid rgba(4, 49, 69, 0.4);
  }
  .modal button.danger {
    background-color: #e56245;
    border: 1px solid rgba(92, 27, 13, 0.4);
  }
  .modal button.cancel {
    color: #0a8cc6;
    text-decoration: underline;
    border: none;
    background-color: white;
  }
  .modal button:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: inherit;
  }

  .uvSigninSection {
    max-width: 500px;
    padding: 10px;
  }
  .uvAccessDeniedSignin,
  .uvAccessDeniedSignin .uvSignin {
    display: block !important;
  }
}