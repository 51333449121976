.site3 {

  .uv-search-results-container {
    width: 900px;
    float: left;
  }

  .uvModuleSection-search .uvField-search {

    .uvFieldButton {
      @apply w-5;
      border: none;
      border-radius: 0;
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: 0px;
      text-overflow: clip;

      box-shadow: none;

      &.uvSearchResults {
        background-color: transparent;
      }

      .uvFieldButtonImage-sidebar {
        display: none;
      }
    }
    .search-icon {
      @apply absolute;
      top: 19px;
      right: 20px;
    }
  }

  .uvSidebarSearch {
    @apply bg-white;
    .search-icon {
      & svg {
        fill: white !important;
      }
    }

    #combined_search_sidebar:focus {
      outline: 3px solid transparent;
      outline-offset: .8em;
    }
  }

  .uvModuleSection-search .uvField-search.uvField-thinking {
    .uvFieldEtc-thinking {
      right: 60px;
    }
  }

  .uvSearchResults {
    outline: 0;
  }

  .uvSearchResults-standalone .uvListItem-searchResult .uvListItemHeader,
  .uvFaqList .uvArticle .uvListItemHeader {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .uvSearchResults-standalone .uvListItem-searchResult .uvListItemBody,
  .uvFaqList .uvArticle .uvListItemBody {
    color: #666f80;
    font-size: 12px;
  }

  .uvSearchResults {
    margin-bottom: 20px;
  }
  .uvSearch-results-clear {
    display: inline-block;
    color: #666f80;
    font-size: 11px;
    text-decoration: underline;
  }
  .uvSearch-more-results {
    @apply bg-base-5 rounded-xs py-1 px-2 uppercase font-bold inline-block text-link my-2;
  }
  .uvSearch-results-count {
    font-size: 11px;
  }

  .uvNoResults {
    display: none;
    font-weight: bold;
    padding-bottom: 10px;
  }


  .uvSearch-results-title {
    display: none;
  }

  .uvSearchResults-ideas, .uvNoResults {
    @apply bg-white shadow-xl absolute border border-solid border-base-10 rounded-sm;
    @apply -mt-3 -ml-3 -mr-3 top-0 left-0 right-0 p-3 pt-17;
    z-index: 1;
  }
  .uvSearchResults .uvNavListItemSubtitle {
    color: black;
  }

  .uvSearch-results-clear {
    display: none;
  }

  /* Search results highlighting */
  .uvPageContainer mark {
    @apply font-bold;
    background: transparent;

    /* For WHCM keep background color present so that text is visible */
    @media (forced-colors: active) {
      background: yellow;
    }
  }

  /* Standalone Search Results Page */

  .uvHelpdeskSearch {
    button[type="submit"] {
      display: none;
    }
  }

  .uvModuleSection-search-site3 {
    @apply w-full mt-20 pt-6 px-8 border-t-4;
  }

  .uvSearchResults-standalone {
    @apply border-t-2;
    border-color: #F5F6F7;
    .uvTabs {
      display: none;
    }
    .uvResults {
      @apply px-8 pt-6 pb-4;
    }
    & mark {
      font-weight: inherit;
    }
    .uvListItem-searchResult {
      display: none;
      &.uvListItem-searchResult-idea {
        @apply mb-4;
        display: block;

        .uvListItemHeader {
          display: flex;

          .uvStyle-status {
            margin: auto 0;
          }
        }
      }
    }
  }

  .uvSearchResults-background {
    @apply absolute w-full bottom-0;
    background-color: #F5F6F7;
    top: 285px;
    z-index: -1;
  }
}