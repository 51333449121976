.site3 {
  .uvNavListItem-feedback .uvNavListItemTitle {
    margin-bottom: 10px;
  }
  .uvNavListItem-feedback .uvNavListItemTitle a {
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 13px;
  }
  .uvNavListItem-feedback {
    background: none;
    padding-left: 25px;
    padding-right: 25px;
  }
  .uvNavListItem-feedback a {
    font-size: 16px;
    font-weight: 600;
  }
  .uvNavListItem-feedback .uvStyle-count {
    font-size: 13px;
    background: none;
  }
  .uvNavListItem-feedback .uvListItem {
    margin-bottom: 8px;
    line-height: 26px;
  }
  .uvNavListItem-feedback .uvListItem .uvStyle-count-label {
    position: static;
  }
  .uvNavListItemSubtitle {
    @apply text-minus1 font-bold uppercase tracking-wide mb-2;
  }
  .uv-article-page .uvSidebar .uvNavListItem-feedback {
    display: none;
  }
  .uv-home-page .uvModule-mainSidebar .uvModuleSection .uvNavList {
    display: none;
  }
  .uv-home-page .uvModule-mainSidebar .uvModuleSection.uvModuleSection-search .uvNavList {
    display: block;
  }
  .uvNavListItem-search {
    background: none;
    padding: 0;
    margin: 0;
  }


  .uvNavListItem-feedback .uvNavListItemTitle {
    margin-bottom: 10px;
  }

  .uvNavListItem-feedback .uvNavListItemTitle a {
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 13px;
  }

  .uvNavListItem-feedback {
    background: none;
    padding-left: 25px;
    padding-right: 25px;
  }

  .uvNavListItem-feedback a {
    font-size: 16px;
    font-weight: 600;
  }

  .uvNavListItem-feedback .uvStyle-count {
    font-size: 13px;
    background: none;
  }

  .uvNavListItem-feedback .uvListItem {
    margin-bottom: 8px;
    line-height: 26px;
  }

  .uvNavListItem-feedback .uvListItem .uvStyle-count-label {
    position: static;
  }

  .uvNavListItemSubtitle {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 0;
    color: white;
  }


  .uvNavListItem-search {
    background: none;
    padding: 0;
    margin: 0;
  }

  .uv-home-page .uvModule-mainSidebar .uvModuleSection .uvNavList {
    display: none;
  }

  .uv-home-page .uvModule-mainSidebar .uvModuleSection.uvModuleSection-search .uvNavList {
    display: block;
  }
}