.site3 {
  .uvForumMessage {
    @apply leading-normal;
    font-weight: 400;
    font-size: 16px;
    color: #536673;
  }
  .uvForumCreateIdea {
    padding: 40px;
    position: relative;
    background-color: white;
    border-top-width: 2px;
    border-top-style: solid;
  }
  .uvForumCreateIdea .uvFieldEtc-thinking {
    right: 20px;
    padding: 0;
  }
  .uvForumCreateIdea .uvField-logged_in {
    padding: 0;
  }
  .uvForumCreateIdea .uvForumCreateIdea-new {
    max-width: 100%;
    margin-bottom: 0;
  }
  .uvForumCreateIdea .uvField-category {
    width: 100%;
    box-sizing: border-box;
  }
  .uvForumCreateIdea .uvSignin,
  .uvForm-comments .uvSignin {
    max-width: 100%;
  }
  .uvForumCreateIdeaLegend {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 38px;
  }
  .uvForumFilter-statuses .uvFieldSelect {
    max-width: 100%;
    padding: 16px;
  }
  .uvForumSearchResults .uvTabs {
    position: relative;
    top: -10px;
  }
  .uvForumFilter-categories,
  .uvForumFilter-my-feedback {
    display: none;
  }

  .forums {
    @apply pb-19 relative;
  }
  .forum-target:hover .forum-heading {
    color: black;
  }

  .uvForumCreateIdea {
    position: relative;
  }
  .uvForumCreateIdea-new {
    margin-bottom: 60px;
    max-width: 476px;
  }
  .uvForumCreateIdeaLegend {
    display: block;
    padding-start: 0;
    padding-end: 0;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .uvForumCreateIdea .uvForumVotesRemaining {
    color: #8f9bb3;
    float: right;
    font-size: 11px;
    line-height: 18px;
    margin: 0 0 10px 10px;
  }
  .uvForumCreateIdea .uvForumVotesRemaining .uvUserData-votes-remaining {
    font-weight: bold;
  }
  @media only screen and (max-width: 320px) {
    .uv-forum .uvIdea {
      padding-left: 60px;
    }
    .uv-forum .uvIdeaVoteBadge {
      width: 40px;
    }
    .uv-forum .uvIdeaVoteCount {
      font-size: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .uv-forum .uvIdeaVoteCount strong {
      font-size: 12px;
      margin-bottom: 0;
    }
    .uv-forum .uvIdeaVoteFormTrigger {
      font-size: 8px;
    }
  }
  .uvForumIndex-alt {
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .uvForumIndex .uvListItem-noresults,
  .uvForumSearchResults .uvListItem-noresults,
  .uvSearchResults .uvListItem-noresults {
    font-size: 24px;
    line-height: 1em;
    padding: 1em;
    text-align: center;
  }
  .uvBody .uvForumsBackground {
    position: absolute;
    right: 0;
    left: 0;
    top: 45px;
    bottom: 0;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: -1;
    &.uvSoftGrayBackground {
      background-color: #F5F6F7;
    }
  }

  .uvForumIndex {
    padding-bottom: 0;
  }

  .uvIdea.uvIdea-show,
  .uvForumCreateIdea {
    @apply p-3 bg-white border-solid border-t-4 mb-1 rounded-xs;

    @media (--viewport-sm) {
      @apply p-7 mb-2;
    }
  }
  .uvForumCreateIdea .uvFieldEtc-thinking {
    right: 20px;
    padding: 0;
  }
  .uvForumCreateIdea .uvField-logged_in {
    padding: 0;
  }
  .uvForumCreateIdea .uvForumCreateIdea-new {
    max-width: 100%;
    margin-bottom: 0;
  }
  .uvForumCreateIdea .uvField-category {
    width: 100%;
    box-sizing: border-box;

    wp-icon.search-icon {
      @apply absolute;
      right: 15px;
      top: 15px;
      transform: rotate(90deg);
    }
  }


  .uvForumCreateIdea .uvSignin,
  .uvForm-comments .uvSignin {
    max-width: 100%;
  }

  .uvForumCreateIdeaLegend {
    @apply font-medium text-plus2 leading-plus1 mb-3;

    @media (--viewport-sm) {
      @apply mb-6;
    }
  }

  @media (--viewport-sm) {
    .uvTabs .uvTabsLeft .uvForumFilter-statuses {
      float: right;
      width: 45%;
    }
  }
  .uvBreadcrumbs {
    @apply mt-4 mb-3;
    a {
      @apply bg-base-5 rounded-xs py-1 px-2 uppercase font-medium text-minus1;
    }
  }

  .uvTabsLeft {
    @apply flex flex-col;
    @media (--viewport-sm) { @apply flex-row flex-wrap; }

    .uvForumFilter { @apply mb-1; }

    .uvTab {
      @apply border-base-10 border-solid border rounded-xs;
      @media (--viewport-sm) { @apply rounded-none; }

      &.uvTab-existingideas { border-radius: 3px; line-height: 24px; }
    }

    @media (--viewport-sm) {
      .uvForumFilter-hot .uvTab {
        border-radius: 3px 0 0 3px;
        border-right: 0;
      }

      .uvForumFilter-new {
        @apply mr-3;

        .uvTab {
          border-radius: 0 3px 3px 0;
          border-left: 0;
        }
      }
    }

    .uvForumFilter-statuses {
      @apply mb-0;

      span {
        @apply rounded-xs;
      }
    }
  }

  .uvForumFilter-categories,
  .uvForumFilter-my-feedback {
    display: none;
  }

  .uv-forum .uvPageHeader {
    @apply px-2;
    .uvPageTitle {
      @apply font-medium;
    }

    @media (--viewport-md) {
      @apply px-6 mb-12;
    }
  }



  .uvRow.forums-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 1172px;
    .forum {
      width: 100%;
      @media (--viewport-md) {
        margin: 0;
        width: 30.666666%;
        margin-bottom: 3%;
        margin-left: 4%;
        &:nth-child(3n+1) {
          margin-left: 0;
        }
        &:only-child {
          width: 100%;
        }
        &:nth-last-child(2):first-child,
        &:nth-last-child(2):first-child + .forum {
          width: 48%;
        }
      }
    }
  }

  .forum {
    @apply relative  m-0 p-0 bg-white shadow-lg border-link border-t-4 rounded-xs w-full mb-2;
    &:only-child {
      width: 100%;
    }
  }

  .forum-target {
    @apply block py-6 px-5;
    @apply no-underline !important;
    min-height: calc(6px * 30);
  }

  .forum-heading {
    @apply text-plus2 leading-plus1 mb-1;
  }

  .forum-count {
    @apply text-normal;
  }

  .forum-arrow {
    @apply absolute right-0 bottom-0 mr-4 mb-3 w-2 h-2;
  }

  .forum-lock {
    @apply absolute right-0 top-0 mr-4 mt-3 w-2 h-2;
  }

  .forum-target:hover {
    & .forum-heading {
      color: black;
    }
    & .forum-arrow svg {
      stroke: black !important;
    }
    & .forum-lock svg {
      stroke: black !important;
    }
  }
}
