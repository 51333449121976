.site3 {
  .uvContainer.uvBody {
    &.newProfile {
      @apply bg-base-5;
      min-height: calc(100vh - 215px);
      height: 100%;
    }
  }
  .newProfile {
    .signUpForm {
      @apply flex justify-center py-5;
    }
    .uvPageHeader{
      margin-bottom: 15px !important;
    }
    .uvPageTitle{
      @apply text-plus2 font-medium flex justify-center w-full;
    }
    .subdomainLink {
      @apply hidden;
    }
    .uvSigninSection {
      @apply bg-white p-6 shadow-xl;
    }
    .uvStyle-helpText {
      @apply hidden;
    }
    .agreement{
      @apply text-minus1 mb-5;
      padding-top: 0 !important;
    }
    .uvStyle-button {
      @apply bg-link;
    }
    img.footer-logo {
      @apply hidden;
    }
    .uvField {
      @apply text-minus1;
    }
  }
}
