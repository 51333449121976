.site3 {
  .uvError-more-info-container {
    display: none;
    padding-top: 10px;
  }
  .truncated-text {
    display: inline;
  }

  .uvHelp {
    cursor: help;
    font-size: 11px;
    font-weight: bold;
  }

  .hidden,
  .HIDE {
    display: none;
  }

  .preserve-formatting {
    white-space: pre-wrap;
  }

  .uvSpinner {
    background: url(https://widget.uservoice.com/pkg/clients/_shared/signin_throbber-a835757d55ff0629266d1d264e2cef12b6e0dcedffe3263aa72bc34159856cef.gif)
      50% 50% no-repeat;
    display: none;
    font-size: 11px;
    line-height: 16px;
    text-indent: -9000px;
    width: 16px;
  }
  .uvSpinning .uvSpinner {
    display: inline-block;
  }
  .uvFormSpinner {
    background: black;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    color: white;
  }

  .uvSpinning .uvFieldEtc-thinking {
    display: inline-block;
  }

  .uvSpinning .uvStyle-cancel {
    display: none;
  }

  .uvNotice {
    background: #ffea80;
    clear: both;
    color: #000;
    font-size: 12px;
    line-height: 1.3em;
    margin-bottom: 10px;
    padding: 10px;
  }
  .uvNotice > :last-child {
    margin-bottom: 0;
  }
  .uvModuleSection .uvNotice {
    margin-right: -10px;
    margin-left: -10px;
  }
  .uvNoticeHeader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.7em;
  }
  .uvNotice a {
    text-decoration: underline;
  }
  .uvNotice p {
    margin-bottom: 0.7em;
  }

  .uvMessageLandingPage {
    padding: 100px 0;
    text-align: center;
  }

  .uvIcon-private-10 {
    background: url(https://widget.uservoice.com/pkg/clients/_icons/icon.private.10px-707df8615251692a16e4f124c9a304bd056d4ce693b6e68e4600581f7fd90133.png)
      0 0 no-repeat;
    display: inline-block;
    height: 10px;
    text-indent: -9000px;
    width: 10px;
  }
  .uvIcon-private-16 {
    background: url(https://widget.uservoice.com/pkg/clients/_icons/icon.private.16px-0e886a0217485269a8d6ad73c19feba8f93cae633d1bba92193f1d88130fe8f7.png)
      0 0 no-repeat;
    display: inline-block;
    height: 16px;
    text-indent: -9000px;
    width: 16px;
  }
  .uvIcon-alert-48 {
    background: url(https://widget.uservoice.com/pkg/icons/classic/48/10-cebd8b3c51fad42895c6cb8a5d57dfabe5bd58c7db509f19e47daf3bce2297f1.png)
      0 0 no-repeat;
    display: inline-block;
    padding-left: 64px;
    min-height: 48px;
    line-height: 48px;
  }

  .uvLinkShowMoreIdeas {
    font-size: 11px;
    color: #666f80;
    text-decoration: underline;
  }

  .error-bubble {
    background: #c02a1c;
    border: 1px solid #c02a1c;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIyNi42NjY2NyUiIHN0b3AtY29sb3I9IiNjMDJhMWMiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMDJhMWMiIHN0b3Atb3BhY2l0eT0iMC43NSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background: -webkit-gradient(
      linear,
      50% 0%,
      50% 30,
      color-stop(26.66667%, #c02a1c),
      color-stop(100%, rgba(192, 42, 28, 0.75))
    );
    background: -moz-linear-gradient(#c02a1c 8px, rgba(192, 42, 28, 0.75) 30px);
    background: -webkit-linear-gradient(
      #c02a1c 8px,
      rgba(192, 42, 28, 0.75) 30px
    );
    background: linear-gradient(#c02a1c 8px, rgba(192, 42, 28, 0.75) 30px);
    border-radius: 5px;
    -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 2px 0 -1px,
      rgba(0, 0, 0, 0.35) 0 4px 6px -2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 2px 0 -1px,
      rgba(0, 0, 0, 0.35) 0 4px 6px -2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 0 -1px,
      rgba(0, 0, 0, 0.35) 0 4px 6px -2px;
    color: white;
    display: none;
    padding: 6px 8px;
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    font-weight: 200;
    text-shadow: rgba(0, 0, 0, 0.5) 0 1px 0;
    z-index: 100;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: linear 100ms;
    pointer-events: none;
    max-width: 350px;
  }
  .error-bubble::before {
    content: "\0020";
    width: 10px;
    height: 10px;
    background: #c02a1c;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    margin-top: -11px;
    margin-left: -6px;
    z-index: 99;
    pointer-events: none;
  }
  .error-with-field :focus + .error-bubble {
    margin-top: 4px;
    display: block;
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
  .error-bubble.above {
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIyNi42NjY2NyUiIHN0b3AtY29sb3I9IiNjMDJhMWMiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMDJhMWMiIHN0b3Atb3BhY2l0eT0iMC43NSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background: -moz-linear-gradient(
      90deg,
      #c02a1c 8px,
      rgba(192, 42, 28, 0.75) 30px
    );
    background: -webkit-linear-gradient(
      90deg,
      #c02a1c 8px,
      rgba(192, 42, 28, 0.75) 30px
    );
    background: linear-gradient(0deg, #c02a1c 8px, rgba(192, 42, 28, 0.75) 30px);
    margin-top: 0;
    top: -28px;
  }
  .error-bubble.above::before {
    margin-top: 16px;
  }
  .error-with-field .textbox {
    border-color: #c02a1c;
    background: #fff
      url(https://widget.uservoice.com/pkg/admin/icons/field_error-5c34aef1913c12e5d1ed3d1882974b75319d6c8b23bce5939c565226bdf25eda.png)
      center right no-repeat;
    padding-right: 24px;
    box-sizing: border-box;
  }
  .error-with-field .textbox.mini {
    background-image: url(https://widget.uservoice.com/pkg/admin/icons/field_error_mini-86a2a1d30821b6686b43a57df8fa9206652743e16df4a08036df78def3c39bac.png);
    padding-right: 16px;
  }
  .error-with-field .textbox:focus {
    border-color: #c02a1c;
    -moz-box-shadow: rgba(192, 42, 28, 0.15) 0 1px 1px inset,
      rgba(255, 255, 255, 0.5) 0 0 0 1px, #c02a1c 0 0 4px 1px;
    -webkit-box-shadow: rgba(192, 42, 28, 0.15) 0 1px 1px inset,
      rgba(255, 255, 255, 0.5) 0 0 0 1px, #c02a1c 0 0 4px 1px;
    box-shadow: rgba(192, 42, 28, 0.15) 0 1px 1px inset,
      rgba(255, 255, 255, 0.5) 0 0 0 1px, #c02a1c 0 0 4px 1px;
  }

  .uv-success-banner {
    visibility: hidden;
    min-width: 250px;
    margin-left: 3px;
    font-size: 15px;
  }
  .uv-success-banner.show {
    visibility: visible;
  }
}