.site3 {

  .uvTabs {
    clear: both;
    float: left;
    font-size: 12px;
    width: 100%;
  }
  .uvTab-selected:focus,
  .uvTab-selected:hover {
    text-decoration: underline;
  }
  .uvTab:hover,
  .uvTab:active,
  .uvTab:focus {
    color: inherit;
  }
  .uvTabsLeft {
    float: left;
  }
  .uvTabsLeft li {
    float: left;
  }
  .uvTabsRight {
    float: right;
  }
  .uvTabsRight li {
    float: left;
    margin-left: 5px;
  }
  .ie7 .uvForumFilter-categories {
    display: none;
  }
  #uvNewIdeaCap {
    display: none;
  }
  @media handheld, only screen and (max-width: 767px) {
    .uvTabsRight {
      float: left;
    }
    .uvTabsRight li {
      margin-left: auto;
      margin-right: 5px;
    }
  }
  .uvTab {
    border-radius: 3px;
    cursor: pointer;
    display: block;
    line-height: 2em;
    margin: 1px 0 5px 0;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
  }
  .ie6 .uvTab {
    background-image: none;
  }
  .uvTab-selected {
    background-position: 50% 100%;
    border-radius: 0;
    color: #fff;
    font-weight: bold;
    margin-bottom: 0;
    min-width: 60px;
    padding-bottom: 5px;
  }
  .ie6 .uvTab-selected {
    margin-bottom: 5px;
    padding-bottom: 0;
  }
  .uvTab-createidea {
    font-weight: bold;
    white-space: nowrap;
  }
  .uvForumFilter-duplicate .uvTab {
    background-color: #ffd9d9;
    color: #b30000;
    font-weight: bold;
  }
  .uvForumFilter-duplicate .uvTab-selected {
    background-color: #b30000;
    color: #fff;
  }
  .uvTabExtended {
    display: none;
  }
  .uvTab-selected .uvTabExtended {
    display: inline;
  }
  .uvTab-selected .uvStyle-count {
    color: #fff;
    font-weight: normal;
  }
  .uvTab-dropdown {
    border: none;
    box-shadow: none;
    line-height: 2em;
    padding-right: 5px;
  }
  .uvTab-dropdown select {
    cursor: pointer;
  }
  .ie6 .uvTab-dropdown {
    display: none;
  }
  .uvTab-dropdown .uvFieldPlaceholder {
    line-height: 2em;
    margin: 0;
  }
  .ie .uvTab-dropdown .uvFieldPlaceholder {
    z-index: 1;
  }
  .uvTab-selected .uvFieldPlaceholder {
    display: none;
  }
  .uvTab-selected .uvFieldSelect {
    color: #fff;
  }
  .ie7 .uvTab-selected .uvFieldSelect {
    color: #000;
  }
  .uvTabSpace {
    line-height: 2em;
    margin-top: 1px;
    padding: 0 0.5em;
  }
  .uvTabs-duplicate-users .uvTab {
    line-height: 1.4;
    min-height: 52px;
    padding-top: 10px;
    padding-left: 60px;
    position: relative;
    text-align: left;
    white-space: normal;
    width: 120px;
  }
  .uvTabs-duplicate-users .uvTab .uvUserBadge {
    margin: 10px;
  }
  .uvTabs {
    background-color: white;
    box-sizing: border-box;
    @apply m-0 px-3 py-4;
  }
  .uvTabs .uvFieldPlaceholder {
    color: #777677;
  }

  .uvTabs#uvNewIdeaCap {
    padding: 0;
    background-color: transparent;
    font-size: 13px;
  }
  .uvTabs .uvTab-selected {
    color: white;
  }


  .uvTabsLeft {
    width: 100%;
  }

  .uvTab {
    border: 1px solid #d7d8d9;
    padding: 11px 35px;
    color: #536673;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 0;
    margin: 0;
    text-decoration: none;
  }
  @media (--viewport-sm) {
    .uvTab {
      border-left: 0;
    }
  }

  .uvTab-dropdown {
    border: 1px solid #d7d8d9;
    padding: 0;
    background-color: transparent;
  }
  .uvTab-dropdown .uvFieldPlaceholder {
    left: 20px;
    top: 11px;
  }

  .uvTab-existingideas {
    padding: 5px 18px;
    font-weight: 700;
  }
}