.site3 {
  .uvFooter {
    color: #666f80;
    font-size: 11px;
    overflow: hidden;
    padding: 40px 0;

    img {
      opacity: 0.45;
      vertical-align: middle;
      filter: grayscale(100%);
      max-width: 125px;
    }

    p {
      line-height: 1.4em;
      margin-bottom: 1.4em;
    }

    .footer-wrap {
      max-width: 1160px;
      margin: 0 auto;

      .utility-links {
        > * {
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (--viewport-sm) {
    .footer-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footer-social-item {
    display: inline-block;
    margin-left: 7px;
  }
}