.site3 {

  .typeset {
    @apply text-normal leading-normal;
    text-rendering: optimizeLegibility;
  }
  .typeset * {
    box-sizing: border-box;
  }
  .typeset div,
  .typeset li,
  .typeset dt,
  .typeset dd,
  .typeset p,
  .typeset pre,
  .typeset caption,
  .typeset th,
  .typeset td,
  .typeset figcaption {
    line-height: 1.4em;
  }
  .typeset caption,
  .typeset dl,
  .typeset dd,
  .typeset figcaption,
  .typeset figure,
  .typeset h1,
  .typeset h2,
  .typeset h3,
  .typeset h4,
  .typeset h5,
  .typeset h6,
  .typeset p,
  .typeset pre,
  .typeset table,
  .typeset ol,
  .typeset ul {
    margin: 1.4em 0;
  }

  .typeset ul {
    list-style: disc;
  }

  .typeset blockquote,
  .typeset ol,
  .typeset ul {
    margin-left: 2.8em;
  }
  .typeset code,
  .typeset pre,
  .typeset th {
    background-color: #f3f6fa;
  }
  .typeset code,
  .typeset pre,
  .typeset th,
  .typeset td {
    color: #324354;
  }
  .typeset pre,
  .typeset table,
  .typeset th,
  .typeset td {
    border: 1px solid #dbe2f2;
  }
  .typeset a {
    text-decoration: underline;
  }
  .typeset strong,
  .typeset b {
    font-weight: bolder;
  }
  .typeset u,
  .typeset em,
  .typeset i {
    font-style: italic;
    text-decoration: none;
  }
  /* Used to render rich text for public status updates */
  .uvUserActionBody .typeset u {
    font-style: italic;
    text-decoration: underline;
  }
  .typeset abbr[title] {
    border-bottom: 1px dotted gray;
  }
  .typeset cite {
    font-style: italic;
  }
  .typeset del {
    color: red;
    text-decoration: line-through;
  }
  .typeset ins {
    color: green;
    text-decoration: none;
  }
  .typeset kbd {
    font-family: monospace;
  }
  .typeset mark {
    background-color: yellow;
    color: black;
  }
  .typeset samp {
    font-family: monospace;
  }
  .typeset small {
    color: gray;
    font-size: 80%;
  }
  .typeset s {
    text-decoration: line-through;
  }
  .typeset sub {
    font-size: 80%;
    vertical-align: sub;
  }
  .typeset sup {
    font-size: 80%;
    vertical-align: super;
  }
  .typeset var {
    font-style: italic;
  }
  .typeset code {
    font-family: monospace;
    padding: 0.1em 0.2em;
  }
  .typeset ol ol,
  .typeset ul ul,
  .typeset ol ul,
  .typeset ul ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  .typeset ol {
    list-style: decimal;
  }
  .typeset ol ol {
    list-style: lower-alpha;
  }
  .typeset ol ol ol {
    list-style: lower-roman;
  }
  .typeset ol ol ol ol {
    list-style: decimal;
  }
  .typeset ul {
    list-style: square;
  }
  .typeset dt {
    font-weight: bold;
  }
  .typeset dd {
    margin-top: 0;
  }
  .typeset pre {
    border-radius: 2px;
    display: block;
    font-family: monospace;
    font-size: 12px;
    max-width: 100%;
    overflow: auto;
    padding: 0.7em;
    white-space: pre-wrap;
  }
  .typeset table {
    border-collapse: collapse;
    table-layout: auto;
  }
  .typeset caption {
    caption-side: top;
    font-weight: bold;
    margin-top: 0;
    text-align: left;
  }
  .typeset th,
  .typeset td {
    padding: 0.7em;
    text-align: left;
  }
  .typeset th {
    font-weight: normal;
  }
  .typeset embed,
  .typeset iframe,
  .typeset img,
  .typeset object {
    display: inline;
    max-width: 100%;
  }
  .typeset figure {
    display: block;
    max-width: 100%;
  }
  .typeset figcaption {
    font-size: 80%;
    text-align: left;
  }
  .typeset > *:first-child {
    margin-top: 0;
  }

  .typeset h1,
  .typeset h2,
  .typeset h3,
  .typeset h4,
  .typeset h5,
  .typeset h6 {
    margin-left: -0.07em;
  }

  .typeset h1 {
    @apply font-medium text-plus6 leading-plus4 mb-3;
  }

  .typeset h2 {
    @apply font-medium text-plus5 leading-plus3 mb-3;
  }

  .typeset h3 {
    @apply font-medium text-plus4 leading-plus2 mb-2;
  }

  .typeset h4 {
    @apply font-medium text-plus3 leading-normal mb-2;
  }

  .typeset h5 {
    @apply font-medium text-plus1 leading-minus1 mb-1;
  }
}
