.site3 {
  .uvIdea-show {
    border-top-width: 2px;
  }

  .uvIdeaVoteBadge {
    @apply mr-auto;
    @media (--viewport-sm) {
      @apply absolute mt-5 top-0 left-0 ml-6;
    }
  }
  .uvIdeaVoteForm {
    position: relative;
  }
  .uvIdeaVoteFormTrigger {
    display: block;
    font-size: 11px;
  }
  .uvIdeaVoteFormTrigger .uvStyle-button {
    white-space: normal;
    overflow-wrap: break-word;
    width: 100%;
  }
  .uvIdeaVoteForm-inline .uvIdeaVoteFormTrigger {
    display: inline-block;
    font-weight: normal;
    line-height: 2em;
    vertical-align: middle;
  }
  .uvIdeaVoteFormToggle {
    display: none;
    outline: 0;
    background-color: #fcfdff;
    border: 1px solid #8f9bb3;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 3px;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: -24px;
    z-index: 999;
    width: auto;

    @media (--viewport-sm) {
      padding: 9px;
      @apply left-0;
    }
  }
  .uvIdeaVoteFormToggle .captcha-voting {
    margin-bottom: 10px;
  }
  .ie7 .uvIdeaVoteFormToggle,
  .ie6 .uvIdeaVoteFormToggle {
    padding-left: 9px;
    top: auto;
    bottom: 0;
  }
  .ie6 .uvIdeaVoteFormToggle {
    bottom: 60px;
  }
  .uvIdeaVoteForm-inline .uvIdeaVoteFormToggle {
    border-color: transparent;
    box-shadow: none;
    margin-top: -25px;
    padding: 0;
    position: relative;
    left: -1px;
    width: auto;
  }
  .ie7 .uvIdeaVoteForm-inline .uvIdeaVoteFormToggle,
  .ie6 .uvIdeaVoteForm-inline .uvIdeaVoteFormToggle {
    padding-left: 0;
  }
  .ie6 .uvIdeaVoteFormToggle .uvSignin {
    width: 350px;
  }
  .uvIdeaVoteFormLegend {
    float: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 2em;
    margin: 0 0 0 -80px;
    text-align: center;
    width: 80px;
  }
  .ie7 .uvIdeaVoteFormLegend,
  .ie6 .uvIdeaVoteFormLegend {
    display: none;
  }
  .uvIdeaVoteFormToggle .uvField {
    background-color: #fff;
    min-width: 350px;
  }
  @media handheld, only screen and (max-width: 500px) {
    .uvIdeaVoteFormToggle .uvField {
      min-width: 240px;
    }
  }
  @media handheld, only screen and (max-width: 320px) {
    .uvIdeaVoteFormToggle .uvField {
      min-width: 170px;
    }
    .uvIdeaVoteFormToggle .uvField-authentication {
      margin-bottom: 40px;
      overflow: visible;
    }
    .uvIdeaVoteFormToggle .uvFieldEtc-forgot_password {
      top: auto;
      right: auto;
      bottom: -30px;
      left: 0;
    }
  }
  .uvIdeaVoteForm-inline .uvField {
    min-width: 0;
  }
  .uvIdeaVoteButtons {
    font-size: 11px;
    white-space: nowrap;
  }
  @media handheld, only screen and (max-width: 320px) {
    .uvIdeaVoteButtons {
      white-space: normal;
    }
    .uvIdeaVoteButtons .uvStyle-button {
      margin-bottom: 5px;
    }
    .uvIdeaVoteButton {
      white-space: nowrap;
      width: 100%;
    }
  }
  .uvVoteFormMeta {
    font-size: 12px;
    vertical-align: middle;
  }
  .uvIdeaVoteVotesRemaining {
    font-size: 11px;
    line-height: 16px;
  }
  .uvIdeaVoteButtonsMeta {
    margin-top: 10px;
  }
  .uvVoteLimit-unlimited .uvIdeaVoteButtonsMeta {
    margin-top: 0;
  }
  .uvVoter {
    position: relative;
  }
  .uvVoter .uvIdeaVoteFormTriggerState-no_votes {
    display: block;
  }
  .uvIdeaVoteForm-inline .uvVoter .uvIdeaVoteFormTriggerState-no_votes {
    display: inline-block;
  }
  .uvVoter .uvIdeaVoteFormTriggerState-you_voted {
    display: none;
    cursor: pointer;
  }
  .uvVoter .uvIdeaVoteFormTriggerState-out_of_votes {
    display: none;
  }
  .uvVoter .uvIdeaVoteFormTriggerState-voting_closed {
    display: none;
  }
  .uvVoter .uvIdeaVoteFormToggle {
    display: none;
  }
  .uvVoter .uvIdeaVoteButtons .uvIdeaVoteButton-0-votes,
  .uvVoter
    .uvIdeaVoteButtons
    .uvIdeaVoteButton-0-votes.uvIdeaVoteButton-disabled {
    display: none;
  }
  .uvIdeaVoteButtons-removevotes {
    border-color: transparent;
    box-shadow: none;
  }
  .uvVoter.uvVoter-logged_out .uvIdeaVoteVotesRemaining {
    display: none;
  }
  .uvVoter.uvVoterMode-voted .uvIdeaVoteFormTriggerState-no_votes {
    display: none;
  }
  .uvVoter.uvVoterMode-voted .uvIdeaVoteFormTriggerState-you_voted {
    display: block;
  }
  .uvIdeaVoteForm-inline
    .uvVoter.uvVoterMode-voted
    .uvIdeaVoteFormTriggerState-you_voted {
    display: inline-block;
  }
  .uvVoterMode-voted .uvIdeaVoteButtons .uvIdeaVoteButton-0-votes {
    display: inline-block;
  }
  .uvVoter.uvVoterMode-voted .uvIdeaVoteButtons .uvIdeaVoteButton-0-votes {
    display: inline-block;
  }
  .uvVoter.uvVoterMode-out_of_votes .uvIdeaVoteFormTriggerState-no_votes {
    display: none;
  }
  .uvVoter.uvVoterMode-out_of_votes .uvIdeaVoteFormTriggerState-out_of_votes {
    display: block;
  }
  .uvIdeaVoteForm-inline
    .uvVoter.uvVoterMode-out_of_votes
    .uvIdeaVoteFormTriggerState-out_of_votes {
    display: inline-block;
  }
  .uvVoter.uvVoterMode-voting_closed .uvIdeaVoteFormTriggerState-no_votes {
    display: none;
  }
  .uvVoter.uvVoterMode-voting_closed .uvIdeaVoteFormTriggerState-voting_closed {
    display: block;
  }
  .uvIdeaVoteForm-inline
    .uvVoter.uvVoterMode-voting_closed
    .uvIdeaVoteFormTriggerState-voting_closed {
    display: inline-block;
  }
  .uv-suggestion-page .uvIdea {
    background-color: white;
    @apply p-3;
    @media (--viewport-sm) {
      padding: 40px;
    }
  }
  .uv-suggestion-page .uvIdea .uvIdeaHeader,
  .uv-suggestion-page .uvIdea .uvIdeaSuggestors,
  .uv-suggestion-page .uvIdea .uvIdeaStatus {
    @media (--viewport-sm) {
      padding-left: 120px;
    }
  }
  .uvIdea {
    min-height: 83px;
    position: relative;
  }
  .uvList-ideas .uvIdea {
    min-height: calc(6px * 22);
    @apply flex flex-wrap px-3 bg-white py-4;

    @media (--viewport-sm) {
      @apply block px-10;
      display: block;
      padding-left: calc(6px * 28);
    }
  }
  .uvIdea-small {
    margin: 0 0 10px 0;
    min-height: 1em;
    padding-left: 0;
  }
  .uvIdeaHeader {
    @apply break-words overflow-hidden w-full;
    @media (--viewport-sm) {
      margin-top: 4px;
    }
  }
  .uvIdeaTitle {
    @apply text-plus1 leading-normal mb-2;
  }
  .uvIdea-show .uvIdeaTitle a {
    color: inherit;
  }
  .uvIdeaDescription {
    color: #292c33;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .uvList-ideas .uvIdeaDescription {
    font-size: 13px;
    margin: 0;
  }
  .uvIdeaContainer {
    overflow: hidden;
    word-wrap: break-word;
  }
  .uvIdea-small .uvIdeaVoteBadge {
    float: left;
    margin: 0 10px 0 0;
    position: static;
    width: 55px;
  }
  .uvModuleSection-recentlyUpdatedIdeas .uvIdeaVoteBadge,
  .ie6 .uvIdea-small .uvIdeaVoteBadge {
    display: none;
  }
  .uvIdea-small .uvIdeaVoteFormTrigger .uvStyle-button {
    padding-right: 0;
    padding-left: 0;
  }

  .uvIdeaMeta {
    @apply text-minus1 text-base-75;
  }
  .uvIdea-small .uvIdeaMeta {
    margin-bottom: auto;
  }
  .uvIdeaMeta a {
    color: inherit;
  }
  .uvIdeaShare {
    float: right;
    margin-left: 20px;
    position: relative;
    z-index: 1;
  }
  .uvIdeaShare .share-button {
    float: left;
  }
  @media handheld, only screen and (max-width: 767px) {
    .uvIdeaShare {
      float: none;
      margin-bottom: 30px;
      margin-left: 0;
    }
  }
  .uvIdeaSectionTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .uvIdeaSuggestors {
    clear: both;
    margin-bottom: 30px;
  }
  .uvIdeaStatus {
    margin-bottom: 60px;
    word-wrap: break-word;
  }
  .uvIdea-small .uvListItemHeader {
    line-height: 18px;
    padding: 3px 0;
  }

  .uvAuditConversionSplit {
    padding-left: 120px;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 14px;
    color: #292c33;
  }

  .uvAuditConversionSplit-header {
    padding-top: 1em;
    border-top: 1px dashed #aaaab2;
    font-weight: bold;
  }

  .uvAuditConversionSplit-break {
    margin-bottom: 1em;
  }

  a {
    text-decoration: underline !important;
  }
}
