.site3 {
  .uvContainer {
    @apply px-3 w-auto;
  }

  .uvContainer .forums {
    max-width: 300px;
    margin: 0 auto;
  }

  .uvContainer.uvBody {
    padding: 0 0 40px 0;
    position: relative;
  }
}
