.uvFlagObject {
    text-decoration: none !important;
}

#flag-confirmation {
    display: inline-flex;
}

#flag-confirmation div {
    background-color: #E41B1B;
    color: white;
    padding: 5px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 13px;
}

.uvFlagObject button.confirm-flag-inappropriate:hover,
.uvFlagObject button.cancel-flag-inappropriate:hover {
    font-weight: 500;
    font-size: 13px;
}

.uvFlagObject button.confirm-flag-inappropriate,
.uvFlagObject button.cancel-flag-inappropriate {
    text-decoration: underline !important;
    font-weight: 500;
    font-size: 13px;
}

.uvFlagObject:hover {
    color: red !important;
}

.flag-button-group {
    margin-left: 10px;
}

.cancel-flag-inappropriate {
    margin-right: 5px;
}

.reporting {
    border: dotted red 2px;
    padding: 6px;
    border-radius: 4px;
}