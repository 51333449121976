.site3 {

  .uvPageContainer {
    display: block;
    visibility: visible !important;
    overflow: visible;
    min-height: 0;
  }

  .uvRow {
    @apply w-auto mx-auto my-0;
    max-width: 1160px;

    &.uvWrapContent {
      display: flex;
      flex-wrap: wrap;

      @media (--viewport-md) {
        display: block;
      }
    }
  }


  .uvPageHeader {
    margin-bottom: 30px;
    min-height: 39px;
  }
  .uvPageTitle {
    @apply text-plus3 text-base leading-plus2 mb-3;
    @media (--viewport-sm) { @apply text-plus6 leading-plus4 }
    img {
      display: none;
    }
  }

  .uvPageTitle a {
    color: inherit;
  }
  .uvPageSubtitle {
    font-size: 24px;
    margin-bottom: 20px;
    position: relative;
  }
  .uvPortalMessage {
    color: #666f80;
  }

  .uvCol-1,
  .uvCol-2,
  .uvCol-3,
  .uvCol-4,
  .uvCol-5,
  .uvCol-6,
  .uvCol-7,
  .uvCol-8,
  .uvCol-9,
  .uvCol-10,
  .uvCol-11 {
    margin-right: 3.8%;
    float: left;
    min-height: 1px;
  }
  .uvCol-1 {
    width: 4.85%;
  }
  .uvCol-2 {
    width: 13.45%;
  }
  .uvCol-3 {
    width: 22.05%;
  }
  .uvCol-4 {
    width: 30.75%;
  }
  .uvCol-5 {
    width: 39.45%;
  }
  .uvCol-6 {
    width: 48%;
  }
  .uvCol-7 {
    width: 56.75%;
  }
  .uvCol-8 {
    width: 65.4%;
  }
  .uvCol-9 {
    width: 74.05%;
  }
  .uvCol-10 {
    width: 82.7%;
  }
  .uvCol-11 {
    width: 91.35%;
  }
  .uvCol-12 {
    width: 100%;
    float: left;
  }
  .uvCol-last {
    margin-right: 0px;
  }
  .uvCol-8 .uvPageHeader {
    margin-bottom: 100px;
  }

  @media handheld, only screen and (max-width: 767px) {
    .uvRow,
    .body,
    .uvContainer {
      width: 100%;
      min-width: 300px;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .uvCol-1,
    .uvCol-2,
    .uvCol-3,
    .uvCol-4,
    .uvCol-5,
    .uvCol-6,
    .uvCol-7,
    .uvCol-8,
    .uvCol-9,
    .uvCol-10,
    .uvCol-11,
    .uvCol-12 {
      width: auto;
      float: none;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}