.site3 {

  .uvStyle-button {
    @apply inline-block font-bold truncate m-0 py-1 px-3 rounded-xs;
    @apply truncate uppercase text-center no-underline text-white text-minus1 leading-normal;
  }

  .uvStyle-button:hover,
  .uvStyle-button:focus {
    cursor: pointer;
    text-decoration: none;
  }
  .uvStyle-button:focus {
    outline-offset: 1px;
  }
  .chrome .uvField .uvStyle-button:hover,
  .chrome .uvField .uvStyle-button:focus {
    box-shadow: none;
  }

  .chrome.win .uvStyle-button:active,
  .chrome.win .uvStyle-button.selected,
  .chrome.win .uvStyle-button-selected,
  .chrome.win .uvStyle-button.selected:focus,
  .chrome.win .uvStyle-button-selected:hover {
    box-shadow: inset 0 1px 0 0 #fff;
  }
  .uvStyle-button[disabled],
  .uvStyle-button[disabled]:active,
  .uvStyle-button[disabled]:focus,
  .uvStyle-button[disabled]:hover,
  .uvStyle-button.disabled,
  .uvStyle-button.disabled:active,
  .uvStyle-button.disabled:focus,
  .uvStyle-button.disabled:hover,
  .uvStyle-button-disabled,
  .uvStyle-button-disabled:active,
  .uvStyle-button-disabled:focus,
  .uvStyle-button-disabled:hover {
    background: #f3f3f3;
    border-top: 1px solid #c5c5c5;
    border-right: 1px solid #cecece;
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #cecece;
    color: #666f80;
    box-shadow: none;
    cursor: not-allowed;
    text-shadow: 0 -1px 1px #ebebeb;
  }
}
