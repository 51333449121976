.site3 {
  .uv-dnd-upload {
    #uvUploadDropArea {
      border: 2px dotted lightgray;
      height: 60px;
      border-radius: 5px;
      background: #F6F6F7;
      position: relative;
      font-size: 14px;
      width: 100%;
    }
    #fileElem {
      display: none;
    }
    #uploadLabel {
      padding: 20px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      cursor: pointer;
    }
    .highlight {
      border-color: #5C7199;
    }
    #gallery {
      margin: 10px 0;
    }
    #gallery > * {
      margin-bottom: 5px;
      font-size: 14px;
    }
    .fileName {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .deleteButton {
      position: relative;
      margin: auto 10px auto 0;
      border: none;
    }
  }
}

