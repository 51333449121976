.site3 {

  .gecko button.uvStyle-button {
    height: 2.2em;
  }
  .uvStyle-link {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    line-height: 1;
    cursor: pointer;
    color: inherit;
  }
  .uvStyle-meta .uvStyle-link,
  .uvIdeaMeta .uvStyle-link {
    line-height: 1.2;
  }
  .uvStyle-link:hover,
  .uvStyle-link:focus {
    text-decoration: underline;
  }
  .typeset .uvStyle-link {
    text-decoration: underline;
  }
  .typeset .uvStyle-link:hover,
  .typeset .uvStyle-link:focus {
    text-decoration: none;
  }

  .uvNotice {
    background: #fff2b3;
    border: 1px solid #ffd500;
    color: #000;
    font-size: 12px;
    line-height: 1.3em;
    margin-bottom: 10px;
    padding: 10px;
  }
  .uvNotice > :last-child {
    margin-bottom: 0;
  }
  .uvModuleSection .uvNotice {
    margin-right: -10px;
    margin-left: -10px;
  }
  .uvNoticeHeader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.7em;
  }
  .uvNotice a {
    text-decoration: underline;
  }
  .uvNotice p {
    margin-bottom: 0.7em;
  }
  .uvStyle-meta {
    color: #666f80;
    font-size: 11px;
    font-weight: normal;
  }
  .uvStyle-meta * {
    white-space: nowrap;
  }
  .uvStyle-meta a,
  .uvStyle-meta .uvStyle-link {
    color: inherit;
    text-decoration: underline;
  }
  .uvStyle-count {
    color: #8f9bb3;
  }
  .uvStyle-cancel {
    font-size: 12px;
    padding: 0 0.5em;
  }
  .uvStyle-cancel.disabled {
    color: #ccc;
  }
  .uvStyle-helpText {
    font-size: 11px;
    margin: -5px 0 10px 0;
    color: #666;
  }
  .uvStyle-separator {
    color: #646b72;
  }

  .uvStyle-count {
    color: #536673;
  }
}