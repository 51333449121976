.site3 {

  .uvIdeaVoteBadge {
    width: 85px;
    min-width: 85px;

    .uvIdeaVoteCount {
      border-radius: 3px 3px 0 0;
      box-shadow: none;
      border: 1px solid #cfd7e6;
      border-bottom: none;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
      padding: 20px 0;
      line-height: 14px;
      padding: 15px 0 11px 0;
      text-align: center;

      span {
        display: none;
      }

      strong {
        display: block;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
  .uvStyle-button.uvIdeaVoteButton,
  .uvIdeaVoteFormTrigger .uvStyle-button {
      border-radius: 0 0 3px 3px;
  }

  .uvForumVotesInner {
    display: inline-block;
    padding: 5px 25px 5px 25px;
    display: inline-block;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 2px 3px;

    .uvUserData-votes-remaining {
      font-weight: bold;
      font-size: 14px;
      padding-right: 3px;
    }

    .uvUserData-votes-remaining-language {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .uvModule-mainSidebar .uvForumVotesRemaining:after {
    position: absolute;
    display: block;
    left: -5px;
    bottom: -5px;
    content: "";
    border-color: #666666 transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    opacity: 0.5;
    height: 0;
    width: 0;
    transform: rotate(90deg);
    _border-left-color: pink;
    _border-bottom-color: pink;
    _border-right-color: pink;
    _filter: chroma(color=pink);
  }

  .uvFiltersExplanation,
  .uvOut-of-Votes-error {
    display: none;
    padding: 12px 32px;
    color: #6f675e;
    font-size: 11px;
    margin-bottom: 10px;
    line-height: 1.3em;
  }
  .uvFiltersExplanation {
    display: block;
    background-color: #f0f8ff;
    padding: 12px;
    clear: both;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .uvOut-of-Votes-error {
    background: url(https://widget.uservoice.com/pkg/icons/classic/16/10-0a52d32febaa57423f9db716b428f2be9ed349b16889c2a3c537ad8524d22217.png)
      10px 10px no-repeat #fff9d9;
  }

  .uvOut-of-Votes-error .uvError-header {
    font-weight: bold;
  }
  .uvOut-of-Votes-error .uvError-list {
    list-style: disc inside;
    line-height: 14px;
  }
  .uvOut-of-votes .uvOut-of-Votes-error {
    display: block;
  }
}