.site3 {
  .uvFileAttachments {
    margin: 0 -9px -9px;
    overflow: hidden;
    padding-top: 1px;
  }
  .uvFileAttachments.uvField-error {
    background: none;
  }
  .uvNewAttachments {
    padding: 0;
  }
  .uvFileAttachments.uvField-error .uvNewAttachments {
    background: #fff7f7;
    border-top: 1px solid #b30000;
    border-bottom: 1px solid #b30000;
    margin: -1px 0;
    padding: 0;
  }
  .uvNewAttachments li {
    padding: 5px 9px 5px 26px;
    position: relative;
  }
  .uvNewAttachments li[data-needs-file="true"] {
    background: #fcf6d7;
    border-border-bottom-left-radius: 3px;
    border-border-bottom-right-radius: 3px;
  }
  .uvFieldFile {
    cursor: pointer;
    font: inherit;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }
  .ie .uvFieldFile {
    font: inherit;
    padding: 2px;
    width: 60%;
  }
  .webkit .uvFieldFile {
    width: 100%;
    font-size: 11px;
  }
  .uvAddAttachment {
    display: block;
    float: left;
    padding: 5px 9px 5px 28px;
    background: url(https://widget.uservoice.com/pkg/admin/icons/attachment_16-659096cc147b24d29122508e1ba38cecfad85d5502d8babe7d8a028734d8ccc6.png)
      9px 7px no-repeat;
    color: #5d7298;
    text-decoration: underline;
    font-size: 11px;
    line-height: 22px;
  }
  .ie6 .uvAddAttachment {
    float: none;
  }
  .uvAddAttachment:hover {
    text-decoration: none;
  }
  .uvRemoveAttachment {
    background: url(https://widget.uservoice.com/pkg/clients/_shared/close-533ae033797a2b0fc1a04a9f95305339fd1979b82f9463149c945fba04b4e873.png)
      0 0 no-repeat;
    display: block;
    cursor: pointer;
    font-size: 11px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    left: 7px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    text-indent: -9000px;
    transition: none;
  }
  .ie6 .uvRemoveAttachment {
    display: inline;
    top: 15px;
    left: -15px;
  }
  .uvRemoveAttachment:focus,
  .uvRemoveAttachment:hover {
    background-position: -16px 0;
  }
  .uvRemoveAttachment {
    background: url(https://widget.uservoice.com/pkg/clients/_shared/close-533ae033797a2b0fc1a04a9f95305339fd1979b82f9463149c945fba04b4e873.png)
      0 0 no-repeat;
    display: block;
    cursor: pointer;
    font-size: 11px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    left: 7px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    text-indent: -9000px;
    transition: none;
  }
  .ie6 .uvRemoveAttachment {
    display: inline;
    top: 15px;
    left: -15px;
  }
  .uvRemoveAttachment:focus,
  .uvRemoveAttachment:hover {
    background-position: -16px 0;
  }

  .uvAttachments {
    clear: both;
    float: left;
    width: 100%;
  }
  .uvAttachment {
    float: left;
    margin-right: 20px;
    margin-bottom: 30px;
    width: 190px;
  }
  .uvListItem .uvAttachment {
    margin-bottom: 10px;
  }
  .gecko .uvAttachment {
    width: auto;
  }
  .uvAttachmentLink {
    float: left;
    width: 100%;
  }
  .uvAttachmentLink:hover {
    text-decoration: none;
  }
  .uvAttachmentIcon {
    float: left;
    height: 32px;
    margin-right: 5px;
    width: 32px;
  }
  .uvAttachmentThumbnail {
    background-color: #fcfdff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 2px solid #fefefe;
    border-right-color: #f1f1f1;
    border-left-color: #f1f1f1;
    border-bottom-color: #e2e2e2;
    border-radius: 1px;
    box-shadow: rgba(0, 0, 0, 0.75) 0 0 1px;
    float: left;
    height: 28px;
    width: 28px;
    margin-right: 5px;
  }
  .uvAttachmentMeta {
    float: left;
    width: 153px;
    max-width: 100%;
  }
  .gecko .uvAttachmentMeta {
    width: auto;
  }
  .uvAttachmentCaption {
    display: block;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .uvAttachmentLink:hover .uvAttachmentCaption {
    text-decoration: underline;
  }
  .uvAttachmentSize {
    color: #646b72;
    display: block;
    font-size: 11px;
    line-height: 16px;
    text-decoration: none;
  }
}