.site3 {
  .uvScorer {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .impScoreButtonContainer {
    display: inline-flex;
    font-size: 14px;
    color: #525260;
    font-weight: 400;
  }

  .impScoreButton {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 4px;
    padding-right: 4px;
    min-width: 95px;
    border: 1px solid #AAAAB2;
    border-radius: 4px;
    .shiftLeft {
      width: 130px;
    }
  }

  .uvIdeaScoreForm {
    legend {
      display: contents;
    }
  }

  .impScoreButton.selected {
    color: #FFF;
    border: none;
  }

  .impScoreButton:hover {
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .impScoreButton:disabled {
    box-shadow: none !important;
    border: 1px solid #797986;
    color: #797986;
  }

  .impScoreButton:hover .tooltiptext {
    visibility: visible;
  }

  .impScoreButton .tooltiptext {
    visibility: hidden;
    font-size: 12px;
    background-color: #63636E;
    color: #fff;
    text-align: center;
    padding: 2px 8px;
    border-radius: 2px;
    margin-top: 24px;
    position: absolute;
    z-index: 1;
  }

  .impScoreButtonGroup {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    text-transform: capitalize;
    margin-left: 28px;
  }

  .uvScorerSigninArea {
    margin-top: 12px;
  }

  .scoreNotification-site3 {
    margin-top: 12px;
    font-size: 12px;
    color: #787885;
  }

  .scoreNotificationContainer {
    width: 71%;
    display: flex;
    justify-content: end;
  }

  @media handheld, only screen and (max-width: 1175px) {
    .impScoreButtonContainer {
      display: block;
    }
    .impScoreButtonGroup {
      padding-top: 12px;
      margin-left: -5px;
      justify-content: start;
    }
    .scoreNotificationContainer {
      width: 100%;
      display: flex;
      justify-content: start;
    }
  }
}