.site3 {
  .uvField {
    background-color: #fcfdff;
    border: 1px solid #8f9bb3;
    border-radius: 3px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 1px 0, rgba(0, 0, 0, 0.1) 0 2px 2px;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 9px;
    position: relative;
    transition: all 0.1s;
  }
  .scrollpane-content .uvField {
    overflow: visible;
  }
  .uvFieldPlaceholder,
  .uvFieldPassword,
  .uvFieldText,
  .uvFieldSelect,
  .uvFieldTextarea {
    @apply font-medium text-base;
    appearance: none;
    background: none;
    border: none;
    box-shadow: none;
    display: block;
    font: inherit;
    line-height: 1em;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    resize: none;
    width: 100%;
  }
  .uvFieldSelect {
    display: inline-block;
    margin: 1px 0 0 1px;
    line-height: 1.14285714em;
  }
  .uvFieldSelect option {
    color: black;
    background-color: white;
  }
  .uvField-select {
    display: inline-block;
  }
  .uvField-select .uvFieldPlaceholder {
    position: static;
    margin-bottom: -1em;
    padding-right: 18px;
  }
  .uvFieldPlaceholder {
    color: #566177;
    margin: 4px 0 0 1px;
    position: absolute;
    transition: all 0.2s;
    white-space: nowrap;
    width: auto;
  }
  .uvField-focus .uvFieldPlaceholder {
    color: #b8becc;
  }
  .uvField-hasvalue .uvFieldPlaceholder {
    display: none;
  }
  .uvFieldTextarea {
    margin: 1px 0 0 1px;
    height: 5em;
  }

  .uvModuleNew-idea-from-sidebar .uvField-focus {
    background: #fffdcd;
    transition: all 0.4s;
  }
  .uvModuleNew-idea-from-sidebar .uvField-hasvalue {
    background: transparent;
  }

  .uvField-disabled .uvFieldEtc-enable {
    display: block;
  }

  .uvField.uvField-focus {
    outline: Highlight solid 2px;
    @apply bg-white;
  }
  @media (-webkit-min-device-pixel-ratio: 0) {
    .uvField.uvField-focus {
      outline: -webkit-focus-ring-color auto;
    }
  }
  .uvField-error {
    background-color: #fff7f7;
    border-color: #b30000;
  }
  .uvField-error .uvFieldText,
  .uvField-error .uvFieldPassword {
    color: #b30000;
  }
  .uvFieldset-error,
  .uvFieldset-error .uvField {
    border-color: #b30000;
  }
  .uvField-disabled,
  .chrome .uvField-disabled {
    border-color: #e6eeff;
    box-shadow: none;
  }
  .uvField-votesXXX {
    margin: 0 0 30px 0;
  }
  .uvField-votesXXX label {
    margin: 0 4px 0 0;
    position: relative;
    z-index: 5;
    background: white;
  }
  .uvField-votesXXX .uvFieldPlaceholderXXX {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cfd7e5;
    padding: 6px 10px;
    display: inline-block;
  }
  .uvField-votesXXX input[type="radio"]:focus + label {
    background: #666666;
  }
  .uvField-votesXXX input[type="radio"] {
    margin-left: 8px;
    margin-top: 10px;
    position: absolute;
    z-index: 1;
    text-indent: -9999px;
    opacity: 0.01;
  }
  .uvField-votesXXX input[type="radio"]:after {
    position: absolute;
    content: "";
    display: block;
    height: 30px;
    width: 35px;
    z-index: 5;
    left: -10px;
    top: -10px;
    background: white;
  }
  .uvField-votesXXX .uvField-votesXXX-selected,
  .uvField-votesXXX input[type="radio"]:checked + label {
    color: #fff;
    background: #666;
  }
  .uvField-idea {
    clear: both;
    font-size: 18px;
    padding-right: 30px;
  }
  .uvField-logged_in,
  .uvField-provider {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 11px;
    line-height: 2em;
    padding: 0;
  }
  .uvField-provider {
    font-weight: bold;
  }
  .uvField-provider img {
    float: left;
    margin-top: 3px;
    padding-right: 5px;
  }

  .uvField-highlight {
    background-color: #ffea80;
    border-color: #ccc9cc;
  }
  .uvFieldEtc {
    background-color: inherit;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: #8f9bb3;
    font-size: 9px;
    height: 16px;
    line-height: 16px;
    margin-top: -10px;
    padding: 2px 7px;
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 100;
  }
  .uvFieldGroup {
    margin-bottom: 10px;
  }
  .uvFieldGroupLegend {
    display: none;
  }
  .uvFieldGroup .uvField {
    border-radius: 0 0 3px 3px;
    margin-top: -2px;
    margin-bottom: 0;
  }
  .uvFieldGroup .uvField-first {
    border-radius: 3px;
    margin-top: 0;
  }
  .uvFieldGroup .uvField-joined {
    border-top: none;
    box-shadow: none;
  }


  .uvField span.error {
    color: #c1272d;
    font-family: "apolline-1", "apolline-2", Georgia, serif;
    font-size: 14px;
    font-style: italic;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
  }

  .uvField-idea .uvFieldEtc-reset {
    display: none;
  }
  .uvFieldEtc-reset {
    display: none;
  }

  .uvField-paused .uvFieldEtc-reset {
    display: block;
  }


  .uvField {
    @apply bg-base-5 border border-base-10 font-medium p-3 mb-2 rounded-xs text-normal;
    box-shadow: none;
    &.uvFieldFocus {
      @apply bg-white;
    }
  }
  .uvField.uvField-consent {
    background-color: transparent;
  }

  /* Hide browser-default clear button */
  input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }


  .uvFieldEtc-reset {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
    background-image: url(https://www.uservoice.com/assets/feedback.uservoice.com/close.svg);
    background-size: 18px;
    padding: 0;
    width: 20px;
    height: 20px;
    right: 20px;
    top: 50%;
    margin-top: -10px;
    cursor: pointer;
  }

  .uvFieldEtc-providers {
    padding-right: 20px;
  }

  .uvField-votesXXX .uvFieldPlaceholderXXX {
    border-radius: 0;
  }

  .uvField-category {
    padding: 0;
  }
  .uvField-category .uvFieldPlaceholder {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -10px;
  }
  .uvField-category .uvFieldSelect {
    padding: 18px;
  }

  .webkit .uvFieldSelect {
    background-image: url(https://www.uservoice.com/assets/feedback.uservoice.com/dropdown-caret.svg);
    background-size: 8px;
    background-position: right 20px top 50%;
    background-repeat: no-repeat;
  }
}