.site3 {
  #fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1104;
    display: none;
  }
  #fancybox-loading div {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 480px;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
  }
  #fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1100;
    display: none;
  }
  #fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    overflow: auto;
    display: none;
  }
  #fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1101;
    outline: none;
    display: none;
  }
  #fancybox-outer {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  #fancybox-content {
    width: 0;
    height: 0;
    padding: 0;
    outline: none;
    position: relative;
    overflow: hidden;
    z-index: 1102;
    border: 0px solid #fff;
  }
  #fancybox-hide-sel-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1101;
  }
  #fancybox-close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: transparent
      url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png) -40px
      0px;
    cursor: pointer;
    z-index: 1103;
    display: none;
  }
  #fancybox-error {
    color: #444;
    font: normal 12px/20px Arial;
    padding: 14px;
    margin: 0;
  }
  #fancybox-img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    line-height: 0;
    vertical-align: top;
  }
  #fancybox-frame {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }
  #fancybox-left,
  #fancybox-right {
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 35%;
    cursor: pointer;
    outline: none;
    background: transparent
      url(https://widget.uservoice.com/pkg/blank-b1442e85b03bdcaf66dc58c7abb98745dd2687d86350be9a298a1d9382ac849b.gif);
    z-index: 1102;
    display: none;
  }
  #fancybox-left {
    left: 0px;
  }
  #fancybox-right {
    right: 0px;
  }
  #fancybox-left-ico,
  #fancybox-right-ico {
    position: absolute;
    top: 50%;
    left: -9999px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    cursor: pointer;
    z-index: 1102;
    display: block;
  }
  #fancybox-left-ico {
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -30px;
  }
  #fancybox-right-ico {
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -60px;
  }
  #fancybox-left:hover,
  #fancybox-right:hover {
    visibility: visible;
  }
  #fancybox-left:hover span {
    left: 20px;
  }
  #fancybox-right:hover span {
    left: auto;
    right: 20px;
  }
  .fancybox-bg {
    position: absolute;
    padding: 0;
    margin: 0;
    border: 0;
    width: 20px;
    height: 20px;
    z-index: 1001;
  }
  #fancybox-bg-n {
    top: -20px;
    left: 0;
    width: 100%;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-x-2af34ef71e58658ae2358171b7508555cddd488dfb1bea735d0787a7ceaa4390.png);
  }
  #fancybox-bg-ne {
    top: -20px;
    right: -20px;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -162px;
  }
  #fancybox-bg-e {
    top: 0;
    right: -20px;
    height: 100%;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-y-08e777880f53e0e527333f100692c9c57ab2d11bf7536a0070b523320f0835b2.png);
    background-position: -20px 0px;
  }
  #fancybox-bg-se {
    bottom: -20px;
    right: -20px;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -182px;
  }
  #fancybox-bg-s {
    bottom: -20px;
    left: 0;
    width: 100%;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-x-2af34ef71e58658ae2358171b7508555cddd488dfb1bea735d0787a7ceaa4390.png);
    background-position: 0px -20px;
  }
  #fancybox-bg-sw {
    bottom: -20px;
    left: -20px;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -142px;
  }
  #fancybox-bg-w {
    top: 0;
    left: -20px;
    height: 100%;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-y-08e777880f53e0e527333f100692c9c57ab2d11bf7536a0070b523320f0835b2.png);
  }
  #fancybox-bg-nw {
    top: -20px;
    left: -20px;
    background-image: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png);
    background-position: -40px -122px;
  }
  #fancybox-title {
    font-family: Helvetica;
    font-size: 12px;
    z-index: 1102;
  }
  .fancybox-title-inside {
    padding-bottom: 10px;
    text-align: center;
    color: #333;
    background: #fff;
    position: relative;
  }
  .fancybox-title-outside {
    padding-top: 10px;
    color: #fff;
  }
  .fancybox-title-over {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    text-align: left;
  }
  #fancybox-title-over {
    padding: 10px;
    background-image: url(https://widget.uservoice.com/pkg/fancy_title_over-d7d1b664875a020cd6577ddc7131018e2abaeafbf8a73a0afad7b1cb5ed3e4ff.png);
    display: block;
  }
  .fancybox-title-float {
    position: absolute;
    left: 0;
    bottom: -20px;
    height: 32px;
  }
  #fancybox-title-float-wrap {
    border: none;
    border-collapse: collapse;
    width: auto;
  }
  #fancybox-title-float-wrap td {
    border: none;
    white-space: nowrap;
  }
  #fancybox-title-float-left {
    padding: 0 0 0 15px;
    background: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png) -40px -90px
      no-repeat;
  }
  #fancybox-title-float-main {
    color: #fff;
    line-height: 29px;
    font-weight: bold;
    padding: 0 0 3px 0;
    background: url(https://widget.uservoice.com/pkg/fancybox-x-2af34ef71e58658ae2358171b7508555cddd488dfb1bea735d0787a7ceaa4390.png)
      0px -40px;
  }
  #fancybox-title-float-right {
    padding: 0 0 0 15px;
    background: url(https://widget.uservoice.com/pkg/fancybox-324b9d944e39c915922db7058a276bd708e68ea5d86762741f14864af2324607.png) -55px -90px
      no-repeat;
  }
  .fancybox-ie6 #fancybox-close {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_close-c39eed10adea9e22dce8b7ad09ed85c3ba7c4149b3906d7f3479ccda1fdf7833.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-left-ico {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_nav_left-4613be9cb65a28b6e15f04587341354b237dfd792687058ddcb4def81bf90a44.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-right-ico {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_nav_right-a0e96aaa7366a5c0f85ec635afc093e183eca293d97dc04cfd62fc4383e141e6.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-title-over {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_title_over-d7d1b664875a020cd6577ddc7131018e2abaeafbf8a73a0afad7b1cb5ed3e4ff.png', sizingMethod='scale');
    zoom: 1;
  }
  .fancybox-ie6 #fancybox-title-float-left {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_title_left-dfb2d789088c740811e416f9d56e039893c0d64c047486399338b2ae90a5f32d.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-title-float-main {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_title_main-94a95b68277b0bdf1ea829c40451af0a65ed48384ffff90ea22b4198d8107daf.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-title-float-right {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_title_right-35c2c6f4f49e24834edb5f3489c07e5d36c11ed4784e29d6ffee06ad508fadb6.png', sizingMethod='scale');
  }
  .fancybox-ie6 #fancybox-bg-w,
  .fancybox-ie6 #fancybox-bg-e,
  .fancybox-ie6 #fancybox-left,
  .fancybox-ie6 #fancybox-right,
  #fancybox-hide-sel-frame {
    height: expression(this.parentNode.clientHeight + "px");
  }
  #fancybox-loading.fancybox-ie6 {
    position: absolute;
    margin-top: 0;
    top: expression(
      (
          -20 + (
              document.documentElement.clientHeight ?
                document.documentElement.clientHeight/2:
                document.body.clientHeight/2
            ) + (ignoreMe = document.documentElement.scrollTop ?
                document.documentElement.scrollTop: document.body.scrollTop)
        ) + "px"
    );
  }
  #fancybox-loading.fancybox-ie6 div {
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_loading-cc8a9e9c1fa964633822fcf482f0c84820d8a764fe265a99351ce9e2b762ef9d.png', sizingMethod='scale');
  }
  .fancybox-ie .fancybox-bg {
    background: transparent !important;
  }
  .fancybox-ie #fancybox-bg-n {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_n-e651c7fc6a23390d32184d4a2b8468d855de428c8752aa41ae0c6538f33935d1.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-ne {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_ne-77c820ec842d2157c2db4142b81a5f995b9f010d05a47c0308b97964a42e894f.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-e {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_e-f048bbfe11fc91c8bf918bcfacdcda8f7cb48f095c058e5c85e4445bbac233ad.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-se {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_se-6c4feb7b5bb1fc5eb137eac567bf0713c126e83fd0b4c8653455fe8eb641a98e.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-s {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_s-09ea1516547b3e85ecd824bf9a39683f5dbd0f8636be8af90ae7457db203ec7e.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-sw {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_sw-4662d9fb1c0259f3d222f056340a299898bf683e5db155377a0b389f7b9bf812.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-w {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_w-022954146d512dadd0e19962e55835716de28d4d546c6eab3ef01a06e5945e21.png', sizingMethod='scale');
  }
  .fancybox-ie #fancybox-bg-nw {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://widget.uservoice.com/pkg/fancy_shadow_nw-384b9e91f2e96f8ea54fbb179cdc819b1360bcdb74894ec7449f3619afd5bf8d.png', sizingMethod='scale');
  }
}