.site3 {

  #tiptip_holder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  #tiptip_holder.tip_top {
    padding-bottom: 10px;
  }
  #tiptip_holder.tip_bottom {
    padding-top: 10px;
  }
  #tiptip_holder.tip_right {
    padding-left: 10px;
  }
  #tiptip_holder.tip_left {
    padding-right: 10px;
  }
  #tiptip_content {
    font-size: 12px;
    color: #ddd;
    line-height: 1.3em;
    text-shadow: 0 0 2px #000;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: #191919;
    background-color: rgba(25, 25, 25, 0.92);
    background-image: -webkit-gradient(
      linear,
      0% 0%,
      0% 100%,
      from(transparent),
      to(#000)
    );
    border-radius: 3px;
    box-shadow: 0 0 3px #555;
  }
  .ie7 #tiptip_content {
    background: #000;
  }
  #tiptip_arrow,
  #tiptip_arrow_inner {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    height: 0;
    width: 0;
  }
  #tiptip_holder.tip_top #tiptip_arrow {
    border-top-color: #fff;
    border-top-color: rgba(255, 255, 255, 0.35);
  }
  #tiptip_holder.tip_bottom #tiptip_arrow {
    border-bottom-color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.35);
  }
  #tiptip_holder.tip_right #tiptip_arrow {
    border-right-color: #fff;
    border-right-color: rgba(255, 255, 255, 0.35);
  }
  #tiptip_holder.tip_left #tiptip_arrow {
    border-left-color: #fff;
    border-left-color: rgba(255, 255, 255, 0.35);
  }
  #tiptip_holder.tip_top #tiptip_arrow_inner {
    margin-top: -7px;
    margin-left: -6px;
    border-top-color: #191919;
    border-top-color: rgba(25, 25, 25, 0.92);
  }
  #tiptip_holder.tip_bottom #tiptip_arrow_inner {
    margin-top: -5px;
    margin-left: -6px;
    border-bottom-color: #191919;
    border-bottom-color: rgba(25, 25, 25, 0.92);
  }
  #tiptip_holder.tip_right #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -5px;
    border-right-color: #191919;
    border-right-color: rgba(25, 25, 25, 0.92);
  }
  #tiptip_holder.tip_left #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -7px;
    border-left-color: #191919;
    border-left-color: rgba(25, 25, 25, 0.92);
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    #tiptip_content {
      padding: 9px 14px;
      background-color: rgba(45, 45, 45, 0.88);
    }
    #tiptip_holder.tip_bottom #tiptip_arrow_inner {
      border-bottom-color: rgba(45, 45, 45, 0.88);
    }
    #tiptip_holder.tip_top #tiptip_arrow_inner {
      border-top-color: rgba(20, 20, 20, 0.92);
    }
  }

  .uvTooltip-alt {
    display: none;
    overflow: visible;
    position: absolute;
    z-index: 0;
    margin-top: -7px;
    width: 495px;
    padding: 13px;
    background-image: url(https://widget.uservoice.com/pkg/clients/site2/black-70opacity-852458e81e6a9bccbf11ea31914b1df18fcb11e34ccde78c869a8c1935d1ddc8.png);
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    transition: all 0.4s;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 1px 0, rgba(0, 0, 0, 0.2) 0 2px 2px;
  }
  .uvTooltip-alt:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.7);
    border-top: 0;
    position: absolute;
    top: -10px;
  }
  .uvModuleNew-idea-from-sidebar .uvTooltip-alt {
    z-index: 5;
    display: block;
  }
  .uvModuleNew-idea-from-sidebar .uvField-hasvalue .uvTooltip-alt {
    opacity: 0;
    display: none;
  }
  .uvTooltip-primary {
    display: block;
  }
  .uvTooltip-outOfVotes {
    display: none;
  }
  .uvOut-of-votes .uvTooltip-primary {
    display: none;
  }
  .uvOut-of-votes .uvTooltip-outOfVotes {
    display: block;
  }
}