.site3 {

  .uvStyle-status {
    border-radius: 2px;
    display: inline-block;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding: 0 0.75em;
    text-transform: uppercase;
  }
  .uvStyle-status-duplicate,
  .uvStyle-status-deleted,
  .uvStyle-status-merged {
    background-color: #802020;
    color: #fff;
  }
  .uvStyle-status-spam {
    background-color: #b30000;
    color: #fff;
  }
  .uvStyle-status-link:focus {
    outline-offset: 1px;
  }
}
